@import "minxin-variables";

@if $trim-color-classes == false {
  // Red

  .red {
    color: unquote("rgb(#{$palette-red-500})") !important;
  }

  .bg-red {
    background-color: unquote("rgb(#{$palette-red-500})") !important;
  }

  .red-50 {
    color: unquote("rgb(#{$palette-red-50})") !important;
  }

  .bg-red-50 {
    background-color: unquote("rgb(#{$palette-red-50})") !important;
  }

  .red-100 {
    color: unquote("rgb(#{$palette-red-100})") !important;
  }

  .bg-red-100 {
    background-color: unquote("rgb(#{$palette-red-100})") !important;
  }

  .red-200 {
    color: unquote("rgb(#{$palette-red-200})") !important;
  }

  .bg-red-200 {
    background-color: unquote("rgb(#{$palette-red-200})") !important;
  }

  .red-300 {
    color: unquote("rgb(#{$palette-red-300})") !important;
  }

  .bg-red-300 {
    background-color: unquote("rgb(#{$palette-red-300})") !important;
  }

  .red-400 {
    color: unquote("rgb(#{$palette-red-400})") !important;
  }

  .bg-red-400 {
    background-color: unquote("rgb(#{$palette-red-400})") !important;
  }

  .red-500 {
    color: unquote("rgb(#{$palette-red-500})") !important;
  }

  .bg-red-500 {
    background-color: unquote("rgb(#{$palette-red-500})") !important;
  }

  .red-600 {
    color: unquote("rgb(#{$palette-red-600})") !important;
  }

  .bg-red-600 {
    background-color: unquote("rgb(#{$palette-red-600})") !important;
  }

  .red-700 {
    color: unquote("rgb(#{$palette-red-700})") !important;
  }

  .bg-red-700 {
    background-color: unquote("rgb(#{$palette-red-700})") !important;
  }

  .red-800 {
    color: unquote("rgb(#{$palette-red-800})") !important;
  }

  .bg-red-800 {
    background-color: unquote("rgb(#{$palette-red-800})") !important;
  }

  .red-900 {
    color: unquote("rgb(#{$palette-red-900})") !important;
  }

  .bg-red-900 {
    background-color: unquote("rgb(#{$palette-red-900})") !important;
  }

  .red-A100 {
    color: unquote("rgb(#{$palette-red-A100})") !important;
  }

  .bg-red-A100 {
    background-color: unquote("rgb(#{$palette-red-A100})") !important;
  }

  .red-A200 {
    color: unquote("rgb(#{$palette-red-A200})") !important;
  }

  .bg-red-A200 {
    background-color: unquote("rgb(#{$palette-red-A200})") !important;
  }

  .red-A400 {
    color: unquote("rgb(#{$palette-red-A400})") !important;
  }

  .bg-red-A400 {
    background-color: unquote("rgb(#{$palette-red-A400})") !important;
  }

  .red-A700 {
    color: unquote("rgb(#{$palette-red-A700})") !important;
  }

  .bg-red-A700 {
    background-color: unquote("rgb(#{$palette-red-A700})") !important;
  }

  // Pink

  .pink {
    color: unquote("rgb(#{$palette-pink-500})") !important;
  }

  .bg-pink {
    background-color: unquote("rgb(#{$palette-pink-500})") !important;
  }

  .pink-50 {
    color: unquote("rgb(#{$palette-pink-50})") !important;
  }

  .bg-pink-50 {
    background-color: unquote("rgb(#{$palette-pink-50})") !important;
  }

  .pink-100 {
    color: unquote("rgb(#{$palette-pink-100})") !important;
  }

  .bg-pink-100 {
    background-color: unquote("rgb(#{$palette-pink-100})") !important;
  }

  .pink-200 {
    color: unquote("rgb(#{$palette-pink-200})") !important;
  }

  .bg-pink-200 {
    background-color: unquote("rgb(#{$palette-pink-200})") !important;
  }

  .pink-300 {
    color: unquote("rgb(#{$palette-pink-300})") !important;
  }

  .bg-pink-300 {
    background-color: unquote("rgb(#{$palette-pink-300})") !important;
  }

  .pink-400 {
    color: unquote("rgb(#{$palette-pink-400})") !important;
  }

  .bg-pink-400 {
    background-color: unquote("rgb(#{$palette-pink-400})") !important;
  }

  .pink-500 {
    color: unquote("rgb(#{$palette-pink-500})") !important;
  }

  .bg-pink-500 {
    background-color: unquote("rgb(#{$palette-pink-500})") !important;
  }

  .pink-600 {
    color: unquote("rgb(#{$palette-pink-600})") !important;
  }

  .bg-pink-600 {
    background-color: unquote("rgb(#{$palette-pink-600})") !important;
  }

  .pink-700 {
    color: unquote("rgb(#{$palette-pink-700})") !important;
  }

  .bg-pink-700 {
    background-color: unquote("rgb(#{$palette-pink-700})") !important;
  }

  .pink-800 {
    color: unquote("rgb(#{$palette-pink-800})") !important;
  }

  .bg-pink-800 {
    background-color: unquote("rgb(#{$palette-pink-800})") !important;
  }

  .pink-900 {
    color: unquote("rgb(#{$palette-pink-900})") !important;
  }

  .bg-pink-900 {
    background-color: unquote("rgb(#{$palette-pink-900})") !important;
  }

  .pink-A100 {
    color: unquote("rgb(#{$palette-pink-A100})") !important;
  }

  .bg-pink-A100 {
    background-color: unquote("rgb(#{$palette-pink-A100})") !important;
  }

  .pink-A200 {
    color: unquote("rgb(#{$palette-pink-A200})") !important;
  }

  .bg-pink-A200 {
    background-color: unquote("rgb(#{$palette-pink-A200})") !important;
  }

  .pink-A400 {
    color: unquote("rgb(#{$palette-pink-A400})") !important;
  }

  .bg-pink-A400 {
    background-color: unquote("rgb(#{$palette-pink-A400})") !important;
  }

  .pink-A700 {
    color: unquote("rgb(#{$palette-pink-A700})") !important;
  }

  .bg-pink-A700 {
    background-color: unquote("rgb(#{$palette-pink-A700})") !important;
  }

  // Purple

  .purple {
    color: unquote("rgb(#{$palette-purple-500})") !important;
  }

  .bg-purple {
    background-color: unquote("rgb(#{$palette-purple-500})") !important;
  }

  .purple-50 {
    color: unquote("rgb(#{$palette-purple-50})") !important;
  }

  .bg-purple-50 {
    background-color: unquote("rgb(#{$palette-purple-50})") !important;
  }

  .purple-100 {
    color: unquote("rgb(#{$palette-purple-100})") !important;
  }

  .bg-purple-100 {
    background-color: unquote("rgb(#{$palette-purple-100})") !important;
  }

  .purple-200 {
    color: unquote("rgb(#{$palette-purple-200})") !important;
  }

  .bg-purple-200 {
    background-color: unquote("rgb(#{$palette-purple-200})") !important;
  }

  .purple-300 {
    color: unquote("rgb(#{$palette-purple-300})") !important;
  }

  .bg-purple-300 {
    background-color: unquote("rgb(#{$palette-purple-300})") !important;
  }

  .purple-400 {
    color: unquote("rgb(#{$palette-purple-400})") !important;
  }

  .bg-purple-400 {
    background-color: unquote("rgb(#{$palette-purple-400})") !important;
  }

  .purple-500 {
    color: unquote("rgb(#{$palette-purple-500})") !important;
  }

  .bg-purple-500 {
    background-color: unquote("rgb(#{$palette-purple-500})") !important;
  }

  .purple-600 {
    color: unquote("rgb(#{$palette-purple-600})") !important;
  }

  .bg-purple-600 {
    background-color: unquote("rgb(#{$palette-purple-600})") !important;
  }

  .purple-700 {
    color: unquote("rgb(#{$palette-purple-700})") !important;
  }

  .bg-purple-700 {
    background-color: unquote("rgb(#{$palette-purple-700})") !important;
  }

  .purple-800 {
    color: unquote("rgb(#{$palette-purple-800})") !important;
  }

  .bg-purple-800 {
    background-color: unquote("rgb(#{$palette-purple-800})") !important;
  }

  .purple-900 {
    color: unquote("rgb(#{$palette-purple-900})") !important;
  }

  .bg-purple-900 {
    background-color: unquote("rgb(#{$palette-purple-900})") !important;
  }

  .purple-A100 {
    color: unquote("rgb(#{$palette-purple-A100})") !important;
  }

  .bg-purple-A100 {
    background-color: unquote("rgb(#{$palette-purple-A100})") !important;
  }

  .purple-A200 {
    color: unquote("rgb(#{$palette-purple-A200})") !important;
  }

  .bg-purple-A200 {
    background-color: unquote("rgb(#{$palette-purple-A200})") !important;
  }

  .purple-A400 {
    color: unquote("rgb(#{$palette-purple-A400})") !important;
  }

  .bg-purple-A400 {
    background-color: unquote("rgb(#{$palette-purple-A400})") !important;
  }

  .purple-A700 {
    color: unquote("rgb(#{$palette-purple-A700})") !important;
  }

  .bg-purple-A700 {
    background-color: unquote("rgb(#{$palette-purple-A700})") !important;
  }

  // Deep Purple.

  .deep-purple {
    color: unquote("rgb(#{$palette-deep-purple-500})") !important;
  }

  .bg-deep-purple {
    background-color: unquote("rgb(#{$palette-deep-purple-500})") !important;
  }

  .deep-purple-50 {
    color: unquote("rgb(#{$palette-deep-purple-50})") !important;
  }

  .bg-deep-purple-50 {
    background-color: unquote("rgb(#{$palette-deep-purple-50})") !important;
  }

  .deep-purple-100 {
    color: unquote("rgb(#{$palette-deep-purple-100})") !important;
  }

  .bg-deep-purple-100 {
    background-color: unquote("rgb(#{$palette-deep-purple-100})") !important;
  }

  .deep-purple-200 {
    color: unquote("rgb(#{$palette-deep-purple-200})") !important;
  }

  .bg-deep-purple-200 {
    background-color: unquote("rgb(#{$palette-deep-purple-200})") !important;
  }

  .deep-purple-300 {
    color: unquote("rgb(#{$palette-deep-purple-300})") !important;
  }

  .bg-deep-purple-300 {
    background-color: unquote("rgb(#{$palette-deep-purple-300})") !important;
  }

  .deep-purple-400 {
    color: unquote("rgb(#{$palette-deep-purple-400})") !important;
  }

  .bg-deep-purple-400 {
    background-color: unquote("rgb(#{$palette-deep-purple-400})") !important;
  }

  .deep-purple-500 {
    color: unquote("rgb(#{$palette-deep-purple-500})") !important;
  }

  .bg-deep-purple-500 {
    background-color: unquote("rgb(#{$palette-deep-purple-500})") !important;
  }

  .deep-purple-600 {
    color: unquote("rgb(#{$palette-deep-purple-600})") !important;
  }

  .bg-deep-purple-600 {
    background-color: unquote("rgb(#{$palette-deep-purple-600})") !important;
  }

  .deep-purple-700 {
    color: unquote("rgb(#{$palette-deep-purple-700})") !important;
  }

  .bg-deep-purple-700 {
    background-color: unquote("rgb(#{$palette-deep-purple-700})") !important;
  }

  .deep-purple-800 {
    color: unquote("rgb(#{$palette-deep-purple-800})") !important;
  }

  .bg-deep-purple-800 {
    background-color: unquote("rgb(#{$palette-deep-purple-800})") !important;
  }

  .deep-purple-900 {
    color: unquote("rgb(#{$palette-deep-purple-900})") !important;
  }

  .bg-deep-purple-900 {
    background-color: unquote("rgb(#{$palette-deep-purple-900})") !important;
  }

  .deep-purple-A100 {
    color: unquote("rgb(#{$palette-deep-purple-A100})") !important;
  }

  .bg-deep-purple-A100 {
    background-color: unquote("rgb(#{$palette-deep-purple-A100})") !important;
  }

  .deep-purple-A200 {
    color: unquote("rgb(#{$palette-deep-purple-A200})") !important;
  }

  .bg-deep-purple-A200 {
    background-color: unquote("rgb(#{$palette-deep-purple-A200})") !important;
  }

  .deep-purple-A400 {
    color: unquote("rgb(#{$palette-deep-purple-A400})") !important;
  }

  .bg-deep-purple-A400 {
    background-color: unquote("rgb(#{$palette-deep-purple-A400})") !important;
  }

  .deep-purple-A700 {
    color: unquote("rgb(#{$palette-deep-purple-A700})") !important;
  }

  .bg-deep-purple-A700 {
    background-color: unquote("rgb(#{$palette-deep-purple-A700})") !important;
  }

  // Indigo

  .indigo {
    color: unquote("rgb(#{$palette-indigo-500})") !important;
  }

  .bg-indigo {
    background-color: unquote("rgb(#{$palette-indigo-500})") !important;
  }

  .indigo-50 {
    color: unquote("rgb(#{$palette-indigo-50})") !important;
  }

  .bg-indigo-50 {
    background-color: unquote("rgb(#{$palette-indigo-50})") !important;
  }

  .indigo-100 {
    color: unquote("rgb(#{$palette-indigo-100})") !important;
  }

  .bg-indigo-100 {
    background-color: unquote("rgb(#{$palette-indigo-100})") !important;
  }

  .indigo-200 {
    color: unquote("rgb(#{$palette-indigo-200})") !important;
  }

  .bg-indigo-200 {
    background-color: unquote("rgb(#{$palette-indigo-200})") !important;
  }

  .indigo-300 {
    color: unquote("rgb(#{$palette-indigo-300})") !important;
  }

  .bg-indigo-300 {
    background-color: unquote("rgb(#{$palette-indigo-300})") !important;
  }

  .indigo-400 {
    color: unquote("rgb(#{$palette-indigo-400})") !important;
  }

  .bg-indigo-400 {
    background-color: unquote("rgb(#{$palette-indigo-400})") !important;
  }

  .indigo-500 {
    color: unquote("rgb(#{$palette-indigo-500})") !important;
  }

  .bg-indigo-500 {
    background-color: unquote("rgb(#{$palette-indigo-500})") !important;
  }

  .indigo-600 {
    color: unquote("rgb(#{$palette-indigo-600})") !important;
  }

  .bg-indigo-600 {
    background-color: unquote("rgb(#{$palette-indigo-600})") !important;
  }

  .indigo-700 {
    color: unquote("rgb(#{$palette-indigo-700})") !important;
  }

  .bg-indigo-700 {
    background-color: unquote("rgb(#{$palette-indigo-700})") !important;
  }

  .indigo-800 {
    color: unquote("rgb(#{$palette-indigo-800})") !important;
  }

  .bg-indigo-800 {
    background-color: unquote("rgb(#{$palette-indigo-800})") !important;
  }

  .indigo-900 {
    color: unquote("rgb(#{$palette-indigo-900})") !important;
  }

  .bg-indigo-900 {
    background-color: unquote("rgb(#{$palette-indigo-900})") !important;
  }

  .indigo-A100 {
    color: unquote("rgb(#{$palette-indigo-A100})") !important;
  }

  .bg-indigo-A100 {
    background-color: unquote("rgb(#{$palette-indigo-A100})") !important;
  }

  .indigo-A200 {
    color: unquote("rgb(#{$palette-indigo-A200})") !important;
  }

  .bg-indigo-A200 {
    background-color: unquote("rgb(#{$palette-indigo-A200})") !important;
  }

  .indigo-A400 {
    color: unquote("rgb(#{$palette-indigo-A400})") !important;
  }

  .bg-indigo-A400 {
    background-color: unquote("rgb(#{$palette-indigo-A400})") !important;
  }

  .indigo-A700 {
    color: unquote("rgb(#{$palette-indigo-A700})") !important;
  }

  .bg-indigo-A700 {
    background-color: unquote("rgb(#{$palette-indigo-A700})") !important;
  }

  // Blue

  .blue {
    color: unquote("rgb(#{$palette-blue-500})") !important;
  }

  .bg-blue {
    background-color: unquote("rgb(#{$palette-blue-500})") !important;
  }

  .blue-50 {
    color: unquote("rgb(#{$palette-blue-50})") !important;
  }

  .bg-blue-50 {
    background-color: unquote("rgb(#{$palette-blue-50})") !important;
  }

  .blue-100 {
    color: unquote("rgb(#{$palette-blue-100})") !important;
  }

  .bg-blue-100 {
    background-color: unquote("rgb(#{$palette-blue-100})") !important;
  }

  .blue-200 {
    color: unquote("rgb(#{$palette-blue-200})") !important;
  }

  .bg-blue-200 {
    background-color: unquote("rgb(#{$palette-blue-200})") !important;
  }

  .blue-300 {
    color: unquote("rgb(#{$palette-blue-300})") !important;
  }

  .bg-blue-300 {
    background-color: unquote("rgb(#{$palette-blue-300})") !important;
  }

  .blue-400 {
    color: unquote("rgb(#{$palette-blue-400})") !important;
  }

  .bg-blue-400 {
    background-color: unquote("rgb(#{$palette-blue-400})") !important;
  }

  .blue-500 {
    color: unquote("rgb(#{$palette-blue-500})") !important;
  }

  .bg-blue-500 {
    background-color: unquote("rgb(#{$palette-blue-500})") !important;
  }

  .blue-600 {
    color: unquote("rgb(#{$palette-blue-600})") !important;
  }

  .bg-blue-600 {
    background-color: unquote("rgb(#{$palette-blue-600})") !important;
  }

  .blue-700 {
    color: unquote("rgb(#{$palette-blue-700})") !important;
  }

  .bg-blue-700 {
    background-color: unquote("rgb(#{$palette-blue-700})") !important;
  }

  .blue-800 {
    color: unquote("rgb(#{$palette-blue-800})") !important;
  }

  .bg-blue-800 {
    background-color: unquote("rgb(#{$palette-blue-800})") !important;
  }

  .blue-900 {
    color: unquote("rgb(#{$palette-blue-900})") !important;
  }

  .bg-blue-900 {
    background-color: unquote("rgb(#{$palette-blue-900})") !important;
  }

  .blue-A100 {
    color: unquote("rgb(#{$palette-blue-A100})") !important;
  }

  .bg-blue-A100 {
    background-color: unquote("rgb(#{$palette-blue-A100})") !important;
  }

  .blue-A200 {
    color: unquote("rgb(#{$palette-blue-A200})") !important;
  }

  .bg-blue-A200 {
    background-color: unquote("rgb(#{$palette-blue-A200})") !important;
  }

  .blue-A400 {
    color: unquote("rgb(#{$palette-blue-A400})") !important;
  }

  .bg-blue-A400 {
    background-color: unquote("rgb(#{$palette-blue-A400})") !important;
  }

  .blue-A700 {
    color: unquote("rgb(#{$palette-blue-A700})") !important;
  }

  .bg-blue-A700 {
    background-color: unquote("rgb(#{$palette-blue-A700})") !important;
  }

  // Light Blue

  .light-blue {
    color: unquote("rgb(#{$palette-light-blue-500})") !important;
  }

  .bg-light-blue {
    background-color: unquote("rgb(#{$palette-light-blue-500})") !important;
  }

  .light-blue-50 {
    color: unquote("rgb(#{$palette-light-blue-50})") !important;
  }

  .bg-light-blue-50 {
    background-color: unquote("rgb(#{$palette-light-blue-50})") !important;
  }

  .light-blue-100 {
    color: unquote("rgb(#{$palette-light-blue-100})") !important;
  }

  .bg-light-blue-100 {
    background-color: unquote("rgb(#{$palette-light-blue-100})") !important;
  }

  .light-blue-200 {
    color: unquote("rgb(#{$palette-light-blue-200})") !important;
  }

  .bg-light-blue-200 {
    background-color: unquote("rgb(#{$palette-light-blue-200})") !important;
  }

  .light-blue-300 {
    color: unquote("rgb(#{$palette-light-blue-300})") !important;
  }

  .bg-light-blue-300 {
    background-color: unquote("rgb(#{$palette-light-blue-300})") !important;
  }

  .light-blue-400 {
    color: unquote("rgb(#{$palette-light-blue-400})") !important;
  }

  .bg-light-blue-400 {
    background-color: unquote("rgb(#{$palette-light-blue-400})") !important;
  }

  .light-blue-500 {
    color: unquote("rgb(#{$palette-light-blue-500})") !important;
  }

  .bg-light-blue-500 {
    background-color: unquote("rgb(#{$palette-light-blue-500})") !important;
  }

  .light-blue-600 {
    color: unquote("rgb(#{$palette-light-blue-600})") !important;
  }

  .bg-light-blue-600 {
    background-color: unquote("rgb(#{$palette-light-blue-600})") !important;
  }

  .light-blue-700 {
    color: unquote("rgb(#{$palette-light-blue-700})") !important;
  }

  .bg-light-blue-700 {
    background-color: unquote("rgb(#{$palette-light-blue-700})") !important;
  }

  .light-blue-800 {
    color: unquote("rgb(#{$palette-light-blue-800})") !important;
  }

  .bg-light-blue-800 {
    background-color: unquote("rgb(#{$palette-light-blue-800})") !important;
  }

  .light-blue-900 {
    color: unquote("rgb(#{$palette-light-blue-900})") !important;
  }

  .bg-light-blue-900 {
    background-color: unquote("rgb(#{$palette-light-blue-900})") !important;
  }

  .light-blue-A100 {
    color: unquote("rgb(#{$palette-light-blue-A100})") !important;
  }

  .bg-light-blue-A100 {
    background-color: unquote("rgb(#{$palette-light-blue-A100})") !important;
  }

  .light-blue-A200 {
    color: unquote("rgb(#{$palette-light-blue-A200})") !important;
  }

  .bg-light-blue-A200 {
    background-color: unquote("rgb(#{$palette-light-blue-A200})") !important;
  }

  .light-blue-A400 {
    color: unquote("rgb(#{$palette-light-blue-A400})") !important;
  }

  .bg-light-blue-A400 {
    background-color: unquote("rgb(#{$palette-light-blue-A400})") !important;
  }

  .light-blue-A700 {
    color: unquote("rgb(#{$palette-light-blue-A700})") !important;
  }

  .bg-light-blue-A700 {
    background-color: unquote("rgb(#{$palette-light-blue-A700})") !important;
  }

  // Cyan

  .cyan {
    color: unquote("rgb(#{$palette-cyan-500})") !important;
  }

  .bg-cyan {
    background-color: unquote("rgb(#{$palette-cyan-500})") !important;
  }

  .cyan-50 {
    color: unquote("rgb(#{$palette-cyan-50})") !important;
  }

  .bg-cyan-50 {
    background-color: unquote("rgb(#{$palette-cyan-50})") !important;
  }

  .cyan-100 {
    color: unquote("rgb(#{$palette-cyan-100})") !important;
  }

  .bg-cyan-100 {
    background-color: unquote("rgb(#{$palette-cyan-100})") !important;
  }

  .cyan-200 {
    color: unquote("rgb(#{$palette-cyan-200})") !important;
  }

  .bg-cyan-200 {
    background-color: unquote("rgb(#{$palette-cyan-200})") !important;
  }

  .cyan-300 {
    color: unquote("rgb(#{$palette-cyan-300})") !important;
  }

  .bg-cyan-300 {
    background-color: unquote("rgb(#{$palette-cyan-300})") !important;
  }

  .cyan-400 {
    color: unquote("rgb(#{$palette-cyan-400})") !important;
  }

  .bg-cyan-400 {
    background-color: unquote("rgb(#{$palette-cyan-400})") !important;
  }

  .cyan-500 {
    color: unquote("rgb(#{$palette-cyan-500})") !important;
  }

  .bg-cyan-500 {
    background-color: unquote("rgb(#{$palette-cyan-500})") !important;
  }

  .cyan-600 {
    color: unquote("rgb(#{$palette-cyan-600})") !important;
  }

  .bg-cyan-600 {
    background-color: unquote("rgb(#{$palette-cyan-600})") !important;
  }

  .cyan-700 {
    color: unquote("rgb(#{$palette-cyan-700})") !important;
  }

  .bg-cyan-700 {
    background-color: unquote("rgb(#{$palette-cyan-700})") !important;
  }

  .cyan-800 {
    color: unquote("rgb(#{$palette-cyan-800})") !important;
  }

  .bg-cyan-800 {
    background-color: unquote("rgb(#{$palette-cyan-800})") !important;
  }

  .cyan-900 {
    color: unquote("rgb(#{$palette-cyan-900})") !important;
  }

  .bg-cyan-900 {
    background-color: unquote("rgb(#{$palette-cyan-900})") !important;
  }

  .cyan-A100 {
    color: unquote("rgb(#{$palette-cyan-A100})") !important;
  }

  .bg-cyan-A100 {
    background-color: unquote("rgb(#{$palette-cyan-A100})") !important;
  }

  .cyan-A200 {
    color: unquote("rgb(#{$palette-cyan-A200})") !important;
  }

  .bg-cyan-A200 {
    background-color: unquote("rgb(#{$palette-cyan-A200})") !important;
  }

  .cyan-A400 {
    color: unquote("rgb(#{$palette-cyan-A400})") !important;
  }

  .bg-cyan-A400 {
    background-color: unquote("rgb(#{$palette-cyan-A400})") !important;
  }

  .cyan-A700 {
    color: unquote("rgb(#{$palette-cyan-A700})") !important;
  }

  .bg-cyan-A700 {
    background-color: unquote("rgb(#{$palette-cyan-A700})") !important;
  }

  // Teal

  .teal {
    color: unquote("rgb(#{$palette-teal-500})") !important;
  }

  .bg-teal {
    background-color: unquote("rgb(#{$palette-teal-500})") !important;
  }

  .teal-50 {
    color: unquote("rgb(#{$palette-teal-50})") !important;
  }

  .bg-teal-50 {
    background-color: unquote("rgb(#{$palette-teal-50})") !important;
  }

  .teal-100 {
    color: unquote("rgb(#{$palette-teal-100})") !important;
  }

  .bg-teal-100 {
    background-color: unquote("rgb(#{$palette-teal-100})") !important;
  }

  .teal-200 {
    color: unquote("rgb(#{$palette-teal-200})") !important;
  }

  .bg-teal-200 {
    background-color: unquote("rgb(#{$palette-teal-200})") !important;
  }

  .teal-300 {
    color: unquote("rgb(#{$palette-teal-300})") !important;
  }

  .bg-teal-300 {
    background-color: unquote("rgb(#{$palette-teal-300})") !important;
  }

  .teal-400 {
    color: unquote("rgb(#{$palette-teal-400})") !important;
  }

  .bg-teal-400 {
    background-color: unquote("rgb(#{$palette-teal-400})") !important;
  }

  .teal-500 {
    color: unquote("rgb(#{$palette-teal-500})") !important;
  }

  .bg-teal-500 {
    background-color: unquote("rgb(#{$palette-teal-500})") !important;
  }

  .teal-600 {
    color: unquote("rgb(#{$palette-teal-600})") !important;
  }

  .bg-teal-600 {
    background-color: unquote("rgb(#{$palette-teal-600})") !important;
  }

  .teal-700 {
    color: unquote("rgb(#{$palette-teal-700})") !important;
  }

  .bg-teal-700 {
    background-color: unquote("rgb(#{$palette-teal-700})") !important;
  }

  .teal-800 {
    color: unquote("rgb(#{$palette-teal-800})") !important;
  }

  .bg-teal-800 {
    background-color: unquote("rgb(#{$palette-teal-800})") !important;
  }

  .teal-900 {
    color: unquote("rgb(#{$palette-teal-900})") !important;
  }

  .bg-teal-900 {
    background-color: unquote("rgb(#{$palette-teal-900})") !important;
  }

  .teal-A100 {
    color: unquote("rgb(#{$palette-teal-A100})") !important;
  }

  .bg-teal-A100 {
    background-color: unquote("rgb(#{$palette-teal-A100})") !important;
  }

  .teal-A200 {
    color: unquote("rgb(#{$palette-teal-A200})") !important;
  }

  .bg-teal-A200 {
    background-color: unquote("rgb(#{$palette-teal-A200})") !important;
  }

  .teal-A400 {
    color: unquote("rgb(#{$palette-teal-A400})") !important;
  }

  .bg-teal-A400 {
    background-color: unquote("rgb(#{$palette-teal-A400})") !important;
  }

  .teal-A700 {
    color: unquote("rgb(#{$palette-teal-A700})") !important;
  }

  .bg-teal-A700 {
    background-color: unquote("rgb(#{$palette-teal-A700})") !important;
  }

  // Green

  .green {
    color: unquote("rgb(#{$palette-green-500})") !important;
  }

  .bg-green {
    background-color: unquote("rgb(#{$palette-green-500})") !important;
  }

  .green-50 {
    color: unquote("rgb(#{$palette-green-50})") !important;
  }

  .bg-green-50 {
    background-color: unquote("rgb(#{$palette-green-50})") !important;
  }

  .green-100 {
    color: unquote("rgb(#{$palette-green-100})") !important;
  }

  .bg-green-100 {
    background-color: unquote("rgb(#{$palette-green-100})") !important;
  }

  .green-200 {
    color: unquote("rgb(#{$palette-green-200})") !important;
  }

  .bg-green-200 {
    background-color: unquote("rgb(#{$palette-green-200})") !important;
  }

  .green-300 {
    color: unquote("rgb(#{$palette-green-300})") !important;
  }

  .bg-green-300 {
    background-color: unquote("rgb(#{$palette-green-300})") !important;
  }

  .green-400 {
    color: unquote("rgb(#{$palette-green-400})") !important;
  }

  .bg-green-400 {
    background-color: unquote("rgb(#{$palette-green-400})") !important;
  }

  .green-500 {
    color: unquote("rgb(#{$palette-green-500})") !important;
  }

  .bg-green-500 {
    background-color: unquote("rgb(#{$palette-green-500})") !important;
  }

  .green-600 {
    color: unquote("rgb(#{$palette-green-600})") !important;
  }

  .bg-green-600 {
    background-color: unquote("rgb(#{$palette-green-600})") !important;
  }

  .green-700 {
    color: unquote("rgb(#{$palette-green-700})") !important;
  }

  .bg-green-700 {
    background-color: unquote("rgb(#{$palette-green-700})") !important;
  }

  .green-800 {
    color: unquote("rgb(#{$palette-green-800})") !important;
  }

  .bg-green-800 {
    background-color: unquote("rgb(#{$palette-green-800})") !important;
  }

  .green-900 {
    color: unquote("rgb(#{$palette-green-900})") !important;
  }

  .bg-green-900 {
    background-color: unquote("rgb(#{$palette-green-900})") !important;
  }

  .green-A100 {
    color: unquote("rgb(#{$palette-green-A100})") !important;
  }

  .bg-green-A100 {
    background-color: unquote("rgb(#{$palette-green-A100})") !important;
  }

  .green-A200 {
    color: unquote("rgb(#{$palette-green-A200})") !important;
  }

  .bg-green-A200 {
    background-color: unquote("rgb(#{$palette-green-A200})") !important;
  }

  .green-A400 {
    color: unquote("rgb(#{$palette-green-A400})") !important;
  }

  .bg-green-A400 {
    background-color: unquote("rgb(#{$palette-green-A400})") !important;
  }

  .green-A700 {
    color: unquote("rgb(#{$palette-green-A700})") !important;
  }

  .bg-green-A700 {
    background-color: unquote("rgb(#{$palette-green-A700})") !important;
  }

  // Light Green

  .light-green {
    color: unquote("rgb(#{$palette-light-green-500})") !important;
  }

  .bg-light-green {
    background-color: unquote("rgb(#{$palette-light-green-500})") !important;
  }

  .light-green-50 {
    color: unquote("rgb(#{$palette-light-green-50})") !important;
  }

  .bg-light-green-50 {
    background-color: unquote("rgb(#{$palette-light-green-50})") !important;
  }

  .light-green-100 {
    color: unquote("rgb(#{$palette-light-green-100})") !important;
  }

  .bg-light-green-100 {
    background-color: unquote("rgb(#{$palette-light-green-100})") !important;
  }

  .light-green-200 {
    color: unquote("rgb(#{$palette-light-green-200})") !important;
  }

  .bg-light-green-200 {
    background-color: unquote("rgb(#{$palette-light-green-200})") !important;
  }

  .light-green-300 {
    color: unquote("rgb(#{$palette-light-green-300})") !important;
  }

  .bg-light-green-300 {
    background-color: unquote("rgb(#{$palette-light-green-300})") !important;
  }

  .light-green-400 {
    color: unquote("rgb(#{$palette-light-green-400})") !important;
  }

  .bg-light-green-400 {
    background-color: unquote("rgb(#{$palette-light-green-400})") !important;
  }

  .light-green-500 {
    color: unquote("rgb(#{$palette-light-green-500})") !important;
  }

  .bg-light-green-500 {
    background-color: unquote("rgb(#{$palette-light-green-500})") !important;
  }

  .light-green-600 {
    color: unquote("rgb(#{$palette-light-green-600})") !important;
  }

  .bg-light-green-600 {
    background-color: unquote("rgb(#{$palette-light-green-600})") !important;
  }

  .light-green-700 {
    color: unquote("rgb(#{$palette-light-green-700})") !important;
  }

  .bg-light-green-700 {
    background-color: unquote("rgb(#{$palette-light-green-700})") !important;
  }

  .light-green-800 {
    color: unquote("rgb(#{$palette-light-green-800})") !important;
  }

  .bg-light-green-800 {
    background-color: unquote("rgb(#{$palette-light-green-800})") !important;
  }

  .light-green-900 {
    color: unquote("rgb(#{$palette-light-green-900})") !important;
  }

  .bg-light-green-900 {
    background-color: unquote("rgb(#{$palette-light-green-900})") !important;
  }

  .light-green-A100 {
    color: unquote("rgb(#{$palette-light-green-A100})") !important;
  }

  .bg-light-green-A100 {
    background-color: unquote("rgb(#{$palette-light-green-A100})") !important;
  }

  .light-green-A200 {
    color: unquote("rgb(#{$palette-light-green-A200})") !important;
  }

  .bg-light-green-A200 {
    background-color: unquote("rgb(#{$palette-light-green-A200})") !important;
  }

  .light-green-A400 {
    color: unquote("rgb(#{$palette-light-green-A400})") !important;
  }

  .bg-light-green-A400 {
    background-color: unquote("rgb(#{$palette-light-green-A400})") !important;
  }

  .light-green-A700 {
    color: unquote("rgb(#{$palette-light-green-A700})") !important;
  }

  .bg-light-green-A700 {
    background-color: unquote("rgb(#{$palette-light-green-A700})") !important;
  }

  // Lime

  .lime {
    color: unquote("rgb(#{$palette-lime-500})") !important;
  }

  .bg-lime {
    background-color: unquote("rgb(#{$palette-lime-500})") !important;
  }

  .lime-50 {
    color: unquote("rgb(#{$palette-lime-50})") !important;
  }

  .bg-lime-50 {
    background-color: unquote("rgb(#{$palette-lime-50})") !important;
  }

  .lime-100 {
    color: unquote("rgb(#{$palette-lime-100})") !important;
  }

  .bg-lime-100 {
    background-color: unquote("rgb(#{$palette-lime-100})") !important;
  }

  .lime-200 {
    color: unquote("rgb(#{$palette-lime-200})") !important;
  }

  .bg-lime-200 {
    background-color: unquote("rgb(#{$palette-lime-200})") !important;
  }

  .lime-300 {
    color: unquote("rgb(#{$palette-lime-300})") !important;
  }

  .bg-lime-300 {
    background-color: unquote("rgb(#{$palette-lime-300})") !important;
  }

  .lime-400 {
    color: unquote("rgb(#{$palette-lime-400})") !important;
  }

  .bg-lime-400 {
    background-color: unquote("rgb(#{$palette-lime-400})") !important;
  }

  .lime-500 {
    color: unquote("rgb(#{$palette-lime-500})") !important;
  }

  .bg-lime-500 {
    background-color: unquote("rgb(#{$palette-lime-500})") !important;
  }

  .lime-600 {
    color: unquote("rgb(#{$palette-lime-600})") !important;
  }

  .bg-lime-600 {
    background-color: unquote("rgb(#{$palette-lime-600})") !important;
  }

  .lime-700 {
    color: unquote("rgb(#{$palette-lime-700})") !important;
  }

  .bg-lime-700 {
    background-color: unquote("rgb(#{$palette-lime-700})") !important;
  }

  .lime-800 {
    color: unquote("rgb(#{$palette-lime-800})") !important;
  }

  .bg-lime-800 {
    background-color: unquote("rgb(#{$palette-lime-800})") !important;
  }

  .lime-900 {
    color: unquote("rgb(#{$palette-lime-900})") !important;
  }

  .bg-lime-900 {
    background-color: unquote("rgb(#{$palette-lime-900})") !important;
  }

  .lime-A100 {
    color: unquote("rgb(#{$palette-lime-A100})") !important;
  }

  .bg-lime-A100 {
    background-color: unquote("rgb(#{$palette-lime-A100})") !important;
  }

  .lime-A200 {
    color: unquote("rgb(#{$palette-lime-A200})") !important;
  }

  .bg-lime-A200 {
    background-color: unquote("rgb(#{$palette-lime-A200})") !important;
  }

  .lime-A400 {
    color: unquote("rgb(#{$palette-lime-A400})") !important;
  }

  .bg-lime-A400 {
    background-color: unquote("rgb(#{$palette-lime-A400})") !important;
  }

  .lime-A700 {
    color: unquote("rgb(#{$palette-lime-A700})") !important;
  }

  .bg-lime-A700 {
    background-color: unquote("rgb(#{$palette-lime-A700})") !important;
  }

  // Yellow

  .yellow {
    color: unquote("rgb(#{$palette-yellow-500})") !important;
  }

  .bg-yellow {
    background-color: unquote("rgb(#{$palette-yellow-500})") !important;
  }

  .yellow-50 {
    color: unquote("rgb(#{$palette-yellow-50})") !important;
  }

  .bg-yellow-50 {
    background-color: unquote("rgb(#{$palette-yellow-50})") !important;
  }

  .yellow-100 {
    color: unquote("rgb(#{$palette-yellow-100})") !important;
  }

  .bg-yellow-100 {
    background-color: unquote("rgb(#{$palette-yellow-100})") !important;
  }

  .yellow-200 {
    color: unquote("rgb(#{$palette-yellow-200})") !important;
  }

  .bg-yellow-200 {
    background-color: unquote("rgb(#{$palette-yellow-200})") !important;
  }

  .yellow-300 {
    color: unquote("rgb(#{$palette-yellow-300})") !important;
  }

  .bg-yellow-300 {
    background-color: unquote("rgb(#{$palette-yellow-300})") !important;
  }

  .yellow-400 {
    color: unquote("rgb(#{$palette-yellow-400})") !important;
  }

  .bg-yellow-400 {
    background-color: unquote("rgb(#{$palette-yellow-400})") !important;
  }

  .yellow-500 {
    color: unquote("rgb(#{$palette-yellow-500})") !important;
  }

  .bg-yellow-500 {
    background-color: unquote("rgb(#{$palette-yellow-500})") !important;
  }

  .yellow-600 {
    color: unquote("rgb(#{$palette-yellow-600})") !important;
  }

  .bg-yellow-600 {
    background-color: unquote("rgb(#{$palette-yellow-600})") !important;
  }

  .yellow-700 {
    color: unquote("rgb(#{$palette-yellow-700})") !important;
  }

  .bg-yellow-700 {
    background-color: unquote("rgb(#{$palette-yellow-700})") !important;
  }

  .yellow-800 {
    color: unquote("rgb(#{$palette-yellow-800})") !important;
  }

  .bg-yellow-800 {
    background-color: unquote("rgb(#{$palette-yellow-800})") !important;
  }

  .yellow-900 {
    color: unquote("rgb(#{$palette-yellow-900})") !important;
  }

  .bg-yellow-900 {
    background-color: unquote("rgb(#{$palette-yellow-900})") !important;
  }

  .yellow-A100 {
    color: unquote("rgb(#{$palette-yellow-A100})") !important;
  }

  .bg-yellow-A100 {
    background-color: unquote("rgb(#{$palette-yellow-A100})") !important;
  }

  .yellow-A200 {
    color: unquote("rgb(#{$palette-yellow-A200})") !important;
  }

  .bg-yellow-A200 {
    background-color: unquote("rgb(#{$palette-yellow-A200})") !important;
  }

  .yellow-A400 {
    color: unquote("rgb(#{$palette-yellow-A400})") !important;
  }

  .bg-yellow-A400 {
    background-color: unquote("rgb(#{$palette-yellow-A400})") !important;
  }

  .yellow-A700 {
    color: unquote("rgb(#{$palette-yellow-A700})") !important;
  }

  .bg-yellow-A700 {
    background-color: unquote("rgb(#{$palette-yellow-A700})") !important;
  }

  // Amber

  .amber {
    color: unquote("rgb(#{$palette-amber-500})") !important;
  }

  .bg-amber {
    background-color: unquote("rgb(#{$palette-amber-500})") !important;
  }

  .amber-50 {
    color: unquote("rgb(#{$palette-amber-50})") !important;
  }

  .bg-amber-50 {
    background-color: unquote("rgb(#{$palette-amber-50})") !important;
  }

  .amber-100 {
    color: unquote("rgb(#{$palette-amber-100})") !important;
  }

  .bg-amber-100 {
    background-color: unquote("rgb(#{$palette-amber-100})") !important;
  }

  .amber-200 {
    color: unquote("rgb(#{$palette-amber-200})") !important;
  }

  .bg-amber-200 {
    background-color: unquote("rgb(#{$palette-amber-200})") !important;
  }

  .amber-300 {
    color: unquote("rgb(#{$palette-amber-300})") !important;
  }

  .bg-amber-300 {
    background-color: unquote("rgb(#{$palette-amber-300})") !important;
  }

  .amber-400 {
    color: unquote("rgb(#{$palette-amber-400})") !important;
  }

  .bg-amber-400 {
    background-color: unquote("rgb(#{$palette-amber-400})") !important;
  }

  .amber-500 {
    color: unquote("rgb(#{$palette-amber-500})") !important;
  }

  .bg-amber-500 {
    background-color: unquote("rgb(#{$palette-amber-500})") !important;
  }

  .amber-600 {
    color: unquote("rgb(#{$palette-amber-600})") !important;
  }

  .bg-amber-600 {
    background-color: unquote("rgb(#{$palette-amber-600})") !important;
  }

  .amber-700 {
    color: unquote("rgb(#{$palette-amber-700})") !important;
  }

  .bg-amber-700 {
    background-color: unquote("rgb(#{$palette-amber-700})") !important;
  }

  .amber-800 {
    color: unquote("rgb(#{$palette-amber-800})") !important;
  }

  .bg-amber-800 {
    background-color: unquote("rgb(#{$palette-amber-800})") !important;
  }

  .amber-900 {
    color: unquote("rgb(#{$palette-amber-900})") !important;
  }

  .bg-amber-900 {
    background-color: unquote("rgb(#{$palette-amber-900})") !important;
  }

  .amber-A100 {
    color: unquote("rgb(#{$palette-amber-A100})") !important;
  }

  .bg-amber-A100 {
    background-color: unquote("rgb(#{$palette-amber-A100})") !important;
  }

  .amber-A200 {
    color: unquote("rgb(#{$palette-amber-A200})") !important;
  }

  .bg-amber-A200 {
    background-color: unquote("rgb(#{$palette-amber-A200})") !important;
  }

  .amber-A400 {
    color: unquote("rgb(#{$palette-amber-A400})") !important;
  }

  .bg-amber-A400 {
    background-color: unquote("rgb(#{$palette-amber-A400})") !important;
  }

  .amber-A700 {
    color: unquote("rgb(#{$palette-amber-A700})") !important;
  }

  .bg-amber-A700 {
    background-color: unquote("rgb(#{$palette-amber-A700})") !important;
  }

  // Orange

  .orange {
    color: unquote("rgb(#{$palette-orange-500})") !important;
  }

  .bg-orange {
    background-color: unquote("rgb(#{$palette-orange-500})") !important;
  }

  .orange-50 {
    color: unquote("rgb(#{$palette-orange-50})") !important;
  }

  .bg-orange-50 {
    background-color: unquote("rgb(#{$palette-orange-50})") !important;
  }

  .orange-100 {
    color: unquote("rgb(#{$palette-orange-100})") !important;
  }

  .bg-orange-100 {
    background-color: unquote("rgb(#{$palette-orange-100})") !important;
  }

  .orange-200 {
    color: unquote("rgb(#{$palette-orange-200})") !important;
  }

  .bg-orange-200 {
    background-color: unquote("rgb(#{$palette-orange-200})") !important;
  }

  .orange-300 {
    color: unquote("rgb(#{$palette-orange-300})") !important;
  }

  .bg-orange-300 {
    background-color: unquote("rgb(#{$palette-orange-300})") !important;
  }

  .orange-400 {
    color: unquote("rgb(#{$palette-orange-400})") !important;
  }

  .bg-orange-400 {
    background-color: unquote("rgb(#{$palette-orange-400})") !important;
  }

  .orange-500 {
    color: unquote("rgb(#{$palette-orange-500})") !important;
  }

  .bg-orange-500 {
    background-color: unquote("rgb(#{$palette-orange-500})") !important;
  }

  .orange-600 {
    color: unquote("rgb(#{$palette-orange-600})") !important;
  }

  .bg-orange-600 {
    background-color: unquote("rgb(#{$palette-orange-600})") !important;
  }

  .orange-700 {
    color: unquote("rgb(#{$palette-orange-700})") !important;
  }

  .bg-orange-700 {
    background-color: unquote("rgb(#{$palette-orange-700})") !important;
  }

  .orange-800 {
    color: unquote("rgb(#{$palette-orange-800})") !important;
  }

  .bg-orange-800 {
    background-color: unquote("rgb(#{$palette-orange-800})") !important;
  }

  .orange-900 {
    color: unquote("rgb(#{$palette-orange-900})") !important;
  }

  .bg-orange-900 {
    background-color: unquote("rgb(#{$palette-orange-900})") !important;
  }

  .orange-A100 {
    color: unquote("rgb(#{$palette-orange-A100})") !important;
  }

  .bg-orange-A100 {
    background-color: unquote("rgb(#{$palette-orange-A100})") !important;
  }

  .orange-A200 {
    color: unquote("rgb(#{$palette-orange-A200})") !important;
  }

  .bg-orange-A200 {
    background-color: unquote("rgb(#{$palette-orange-A200})") !important;
  }

  .orange-A400 {
    color: unquote("rgb(#{$palette-orange-A400})") !important;
  }

  .bg-orange-A400 {
    background-color: unquote("rgb(#{$palette-orange-A400})") !important;
  }

  .orange-A700 {
    color: unquote("rgb(#{$palette-orange-A700})") !important;
  }

  .bg-orange-A700 {
    background-color: unquote("rgb(#{$palette-orange-A700})") !important;
  }

  // Deep Orange

  .deep-orange {
    color: unquote("rgb(#{$palette-deep-orange-500})") !important;
  }

  .bg-deep-orange {
    background-color: unquote("rgb(#{$palette-deep-orange-500})") !important;
  }

  .deep-orange-50 {
    color: unquote("rgb(#{$palette-deep-orange-50})") !important;
  }

  .bg-deep-orange-50 {
    background-color: unquote("rgb(#{$palette-deep-orange-50})") !important;
  }

  .deep-orange-100 {
    color: unquote("rgb(#{$palette-deep-orange-100})") !important;
  }

  .bg-deep-orange-100 {
    background-color: unquote("rgb(#{$palette-deep-orange-100})") !important;
  }

  .deep-orange-200 {
    color: unquote("rgb(#{$palette-deep-orange-200})") !important;
  }

  .bg-deep-orange-200 {
    background-color: unquote("rgb(#{$palette-deep-orange-200})") !important;
  }

  .deep-orange-300 {
    color: unquote("rgb(#{$palette-deep-orange-300})") !important;
  }

  .bg-deep-orange-300 {
    background-color: unquote("rgb(#{$palette-deep-orange-300})") !important;
  }

  .deep-orange-400 {
    color: unquote("rgb(#{$palette-deep-orange-400})") !important;
  }

  .bg-deep-orange-400 {
    background-color: unquote("rgb(#{$palette-deep-orange-400})") !important;
  }

  .deep-orange-500 {
    color: unquote("rgb(#{$palette-deep-orange-500})") !important;
  }

  .bg-deep-orange-500 {
    background-color: unquote("rgb(#{$palette-deep-orange-500})") !important;
  }

  .deep-orange-600 {
    color: unquote("rgb(#{$palette-deep-orange-600})") !important;
  }

  .bg-deep-orange-600 {
    background-color: unquote("rgb(#{$palette-deep-orange-600})") !important;
  }

  .deep-orange-700 {
    color: unquote("rgb(#{$palette-deep-orange-700})") !important;
  }

  .bg-deep-orange-700 {
    background-color: unquote("rgb(#{$palette-deep-orange-700})") !important;
  }

  .deep-orange-800 {
    color: unquote("rgb(#{$palette-deep-orange-800})") !important;
  }

  .bg-deep-orange-800 {
    background-color: unquote("rgb(#{$palette-deep-orange-800})") !important;
  }

  .deep-orange-900 {
    color: unquote("rgb(#{$palette-deep-orange-900})") !important;
  }

  .bg-deep-orange-900 {
    background-color: unquote("rgb(#{$palette-deep-orange-900})") !important;
  }

  .deep-orange-A100 {
    color: unquote("rgb(#{$palette-deep-orange-A100})") !important;
  }

  .bg-deep-orange-A100 {
    background-color: unquote("rgb(#{$palette-deep-orange-A100})") !important;
  }

  .deep-orange-A200 {
    color: unquote("rgb(#{$palette-deep-orange-A200})") !important;
  }

  .bg-deep-orange-A200 {
    background-color: unquote("rgb(#{$palette-deep-orange-A200})") !important;
  }

  .deep-orange-A400 {
    color: unquote("rgb(#{$palette-deep-orange-A400})") !important;
  }

  .bg-deep-orange-A400 {
    background-color: unquote("rgb(#{$palette-deep-orange-A400})") !important;
  }

  .deep-orange-A700 {
    color: unquote("rgb(#{$palette-deep-orange-A700})") !important;
  }

  .bg-deep-orange-A700 {
    background-color: unquote("rgb(#{$palette-deep-orange-A700})") !important;
  }

  // Brown

  .brown {
    color: unquote("rgb(#{$palette-brown-500})") !important;
  }

  .bg-brown {
    background-color: unquote("rgb(#{$palette-brown-500})") !important;
  }

  .brown-50 {
    color: unquote("rgb(#{$palette-brown-50})") !important;
  }

  .bg-brown-50 {
    background-color: unquote("rgb(#{$palette-brown-50})") !important;
  }

  .brown-100 {
    color: unquote("rgb(#{$palette-brown-100})") !important;
  }

  .bg-brown-100 {
    background-color: unquote("rgb(#{$palette-brown-100})") !important;
  }

  .brown-200 {
    color: unquote("rgb(#{$palette-brown-200})") !important;
  }

  .bg-brown-200 {
    background-color: unquote("rgb(#{$palette-brown-200})") !important;
  }

  .brown-300 {
    color: unquote("rgb(#{$palette-brown-300})") !important;
  }

  .bg-brown-300 {
    background-color: unquote("rgb(#{$palette-brown-300})") !important;
  }

  .brown-400 {
    color: unquote("rgb(#{$palette-brown-400})") !important;
  }

  .bg-brown-400 {
    background-color: unquote("rgb(#{$palette-brown-400})") !important;
  }

  .brown-500 {
    color: unquote("rgb(#{$palette-brown-500})") !important;
  }

  .bg-brown-500 {
    background-color: unquote("rgb(#{$palette-brown-500})") !important;
  }

  .brown-600 {
    color: unquote("rgb(#{$palette-brown-600})") !important;
  }

  .bg-brown-600 {
    background-color: unquote("rgb(#{$palette-brown-600})") !important;
  }

  .brown-700 {
    color: unquote("rgb(#{$palette-brown-700})") !important;
  }

  .bg-brown-700 {
    background-color: unquote("rgb(#{$palette-brown-700})") !important;
  }

  .brown-800 {
    color: unquote("rgb(#{$palette-brown-800})") !important;
  }

  .bg-brown-800 {
    background-color: unquote("rgb(#{$palette-brown-800})") !important;
  }

  .brown-900 {
    color: unquote("rgb(#{$palette-brown-900})") !important;
  }

  .bg-brown-900 {
    background-color: unquote("rgb(#{$palette-brown-900})") !important;
  }

  // Grey

  .grey {
    color: unquote("rgb(#{$palette-grey-500})") !important;
  }

  .bg-grey {
    background-color: unquote("rgb(#{$palette-grey-500})") !important;
  }

  .grey-50 {
    color: unquote("rgb(#{$palette-grey-50})") !important;
  }

  .bg-grey-50 {
    background-color: unquote("rgb(#{$palette-grey-50})") !important;
  }

  .grey-100 {
    color: unquote("rgb(#{$palette-grey-100})") !important;
  }

  .bg-grey-100 {
    background-color: unquote("rgb(#{$palette-grey-100})") !important;
  }

  .grey-200 {
    color: unquote("rgb(#{$palette-grey-200})") !important;
  }

  .bg-grey-200 {
    background-color: unquote("rgb(#{$palette-grey-200})") !important;
  }

  .grey-300 {
    color: unquote("rgb(#{$palette-grey-300})") !important;
  }

  .bg-grey-300 {
    background-color: unquote("rgb(#{$palette-grey-300})") !important;
  }

  .grey-400 {
    color: unquote("rgb(#{$palette-grey-400})") !important;
  }

  .bg-grey-400 {
    background-color: unquote("rgb(#{$palette-grey-400})") !important;
  }

  .grey-500 {
    color: unquote("rgb(#{$palette-grey-500})") !important;
  }

  .bg-grey-500 {
    background-color: unquote("rgb(#{$palette-grey-500})") !important;
  }

  .grey-600 {
    color: unquote("rgb(#{$palette-grey-600})") !important;
  }

  .bg-grey-600 {
    background-color: unquote("rgb(#{$palette-grey-600})") !important;
  }

  .grey-700 {
    color: unquote("rgb(#{$palette-grey-700})") !important;
  }

  .bg-grey-700 {
    background-color: unquote("rgb(#{$palette-grey-700})") !important;
  }

  .grey-800 {
    color: unquote("rgb(#{$palette-grey-800})") !important;
  }

  .bg-grey-800 {
    background-color: unquote("rgb(#{$palette-grey-800})") !important;
  }

  .grey-900 {
    color: unquote("rgb(#{$palette-grey-900})") !important;
  }

  .bg-grey-900 {
    background-color: unquote("rgb(#{$palette-grey-900})") !important;
  }

  // Blue Grey

  .blue-grey {
    color: unquote("rgb(#{$palette-blue-grey-500})") !important;
  }

  .bg-blue-grey {
    background-color: unquote("rgb(#{$palette-blue-grey-500})") !important;
  }

  .blue-grey-50 {
    color: unquote("rgb(#{$palette-blue-grey-50})") !important;
  }

  .bg-blue-grey-50 {
    background-color: unquote("rgb(#{$palette-blue-grey-50})") !important;
  }

  .blue-grey-100 {
    color: unquote("rgb(#{$palette-blue-grey-100})") !important;
  }

  .bg-blue-grey-100 {
    background-color: unquote("rgb(#{$palette-blue-grey-100})") !important;
  }

  .blue-grey-200 {
    color: unquote("rgb(#{$palette-blue-grey-200})") !important;
  }

  .bg-blue-grey-200 {
    background-color: unquote("rgb(#{$palette-blue-grey-200})") !important;
  }

  .blue-grey-300 {
    color: unquote("rgb(#{$palette-blue-grey-300})") !important;
  }

  .bg-blue-grey-300 {
    background-color: unquote("rgb(#{$palette-blue-grey-300})") !important;
  }

  .blue-grey-400 {
    color: unquote("rgb(#{$palette-blue-grey-400})") !important;
  }

  .bg-blue-grey-400 {
    background-color: unquote("rgb(#{$palette-blue-grey-400})") !important;
  }

  .blue-grey-500 {
    color: unquote("rgb(#{$palette-blue-grey-500})") !important;
  }

  .bg-blue-grey-500 {
    background-color: unquote("rgb(#{$palette-blue-grey-500})") !important;
  }

  .blue-grey-600 {
    color: unquote("rgb(#{$palette-blue-grey-600})") !important;
  }

  .bg-blue-grey-600 {
    background-color: unquote("rgb(#{$palette-blue-grey-600})") !important;
  }

  .blue-grey-700 {
    color: unquote("rgb(#{$palette-blue-grey-700})") !important;
  }

  .bg-blue-grey-700 {
    background-color: unquote("rgb(#{$palette-blue-grey-700})") !important;
  }

  .blue-grey-800 {
    color: unquote("rgb(#{$palette-blue-grey-800})") !important;
  }

  .bg-blue-grey-800 {
    background-color: unquote("rgb(#{$palette-blue-grey-800})") !important;
  }

  .blue-grey-900 {
    color: unquote("rgb(#{$palette-blue-grey-900})") !important;
  }

  .bg-blue-grey-900 {
    background-color: unquote("rgb(#{$palette-blue-grey-900})") !important;
  }


  //用友UE定制色系


  .u-red {
    color: unquote("rgb(#{$palette-u-red-500})") !important;
  }

  .u-bg-red {
    background-color: unquote("rgb(#{$palette-u-red-500})") !important;
  }
  .u-red-100 {
    color: unquote("rgb(#{$palette-u-red-100})") !important;
  }

  .u-bg-red-100 {
    background-color: unquote("rgb(#{$palette-u-red-100})") !important;
  }
  .u-red-200 {
    color: unquote("rgb(#{$palette-u-red-200})") !important;
  }

  .u-bg-red-200 {
    background-color: unquote("rgb(#{$palette-u-red-200})") !important;
  }
  .u-red-300 {
    color: unquote("rgb(#{$palette-u-red-300})") !important;
  }

  .u-bg-red-300 {
    background-color: unquote("rgb(#{$palette-u-red-300})") !important;
  }
  .u-red-400 {
    color: unquote("rgb(#{$palette-u-red-400})") !important;
  }

  .u-bg-red-400 {
    background-color: unquote("rgb(#{$palette-u-red-400})") !important;
  }
  .u-red-500 {
    color: unquote("rgb(#{$palette-u-red-500})") !important;
  }

  .u-bg-red-500 {
    background-color: unquote("rgb(#{$palette-u-red-500})") !important;
  }
  .u-red-600 {
    color: unquote("rgb(#{$palette-u-red-600})") !important;
  }

  .u-bg-red-600 {
    background-color: unquote("rgb(#{$palette-u-red-600})") !important;
  }
  .u-red-700 {
    color: unquote("rgb(#{$palette-u-red-700})") !important;
  }

  .u-bg-red-700 {
    background-color: unquote("rgb(#{$palette-u-red-700})") !important;
  }
  .u-red-800 {
    color: unquote("rgb(#{$palette-u-red-800})") !important;
  }

  .u-bg-red-800 {
    background-color: unquote("rgb(#{$palette-u-red-800})") !important;
  }
  .u-red-900 {
    color: unquote("rgb(#{$palette-u-red-900})") !important;
  }

  .u-bg-red-900 {
    background-color: unquote("rgb(#{$palette-u-red-900})") !important;
  }


  .u-orange {
    color: unquote("rgb(#{$palette-u-orange-500})") !important;
  }

  .u-bg-orange {
    background-color: unquote("rgb(#{$palette-u-orange-500})") !important;
  }
  .u-orange-100 {
    color: unquote("rgb(#{$palette-u-orange-100})") !important;
  }

  .u-bg-orange-100 {
    background-color: unquote("rgb(#{$palette-u-orange-100})") !important;
  }
  .u-orange-200 {
    color: unquote("rgb(#{$palette-u-orange-200})") !important;
  }

  .u-bg-orange-200 {
    background-color: unquote("rgb(#{$palette-u-orange-200})") !important;
  }
  .u-orange-300 {
    color: unquote("rgb(#{$palette-u-orange-300})") !important;
  }

  .u-bg-orange-300 {
    background-color: unquote("rgb(#{$palette-u-orange-300})") !important;
  }
  .u-orange-400 {
    color: unquote("rgb(#{$palette-u-orange-400})") !important;
  }

  .u-bg-orange-400 {
    background-color: unquote("rgb(#{$palette-u-orange-400})") !important;
  }
  .u-orange-500 {
    color: unquote("rgb(#{$palette-u-orange-500})") !important;
  }

  .u-bg-orange-500 {
    background-color: unquote("rgb(#{$palette-u-orange-500})") !important;
  }
  .u-orange-600 {
    color: unquote("rgb(#{$palette-u-orange-600})") !important;
  }

  .u-bg-orange-600 {
    background-color: unquote("rgb(#{$palette-u-orange-600})") !important;
  }
  .u-orange-700 {
    color: unquote("rgb(#{$palette-u-orange-700})") !important;
  }

  .u-bg-orange-700 {
    background-color: unquote("rgb(#{$palette-u-orange-700})") !important;
  }
  .u-orange-800 {
    color: unquote("rgb(#{$palette-u-orange-800})") !important;
  }

  .u-bg-orange-800 {
    background-color: unquote("rgb(#{$palette-u-orange-800})") !important;
  }
  .u-orange-900 {
    color: unquote("rgb(#{$palette-u-orange-900})") !important;
  }

  .u-bg-orange-900 {
    background-color: unquote("rgb(#{$palette-u-orange-900})") !important;
  }


  .u-yellow {
    color: unquote("rgb(#{$palette-u-yellow-500})") !important;
  }

  .u-bg-yellow {
    background-color: unquote("rgb(#{$palette-u-yellow-500})") !important;
  }
  .u-yellow-100 {
    color: unquote("rgb(#{$palette-u-yellow-100})") !important;
  }

  .u-bg-yellow-100 {
    background-color: unquote("rgb(#{$palette-u-yellow-100})") !important;
  }
  .u-yellow-200 {
    color: unquote("rgb(#{$palette-u-yellow-200})") !important;
  }

  .u-bg-yellow-200 {
    background-color: unquote("rgb(#{$palette-u-yellow-200})") !important;
  }
  .u-yellow-300 {
    color: unquote("rgb(#{$palette-u-yellow-300})") !important;
  }

  .u-bg-yellow-300 {
    background-color: unquote("rgb(#{$palette-u-yellow-300})") !important;
  }
  .u-yellow-400 {
    color: unquote("rgb(#{$palette-u-yellow-400})") !important;
  }

  .u-bg-yellow-400 {
    background-color: unquote("rgb(#{$palette-u-yellow-400})") !important;
  }
  .u-yellow-500 {
    color: unquote("rgb(#{$palette-u-yellow-500})") !important;
  }

  .u-bg-yellow-500 {
    background-color: unquote("rgb(#{$palette-u-yellow-500})") !important;
  }
  .u-yellow-600 {
    color: unquote("rgb(#{$palette-u-yellow-600})") !important;
  }

  .u-bg-yellow-600 {
    background-color: unquote("rgb(#{$palette-u-yellow-600})") !important;
  }
  .u-yellow-700 {
    color: unquote("rgb(#{$palette-u-yellow-700})") !important;
  }

  .u-bg-yellow-700 {
    background-color: unquote("rgb(#{$palette-u-yellow-700})") !important;
  }
  .u-yellow-800 {
    color: unquote("rgb(#{$palette-u-yellow-800})") !important;
  }

  .u-bg-yellow-800 {
    background-color: unquote("rgb(#{$palette-u-yellow-800})") !important;
  }
  .u-yellow-900 {
    color: unquote("rgb(#{$palette-u-yellow-900})") !important;
  }

  .u-bg-yellow-900 {
    background-color: unquote("rgb(#{$palette-u-yellow-900})") !important;
  }


  .u-green {
    color: unquote("rgb(#{$palette-u-green-500})") !important;
  }

  .u-bg-green {
    background-color: unquote("rgb(#{$palette-u-green-500})") !important;
  }
  .u-green-100 {
    color: unquote("rgb(#{$palette-u-green-100})") !important;
  }

  .u-bg-green-100 {
    background-color: unquote("rgb(#{$palette-u-green-100})") !important;
  }
  .u-green-200 {
    color: unquote("rgb(#{$palette-u-green-200})") !important;
  }

  .u-bg-green-200 {
    background-color: unquote("rgb(#{$palette-u-green-200})") !important;
  }
  .u-green-300 {
    color: unquote("rgb(#{$palette-u-green-300})") !important;
  }

  .u-bg-green-300 {
    background-color: unquote("rgb(#{$palette-u-green-300})") !important;
  }
  .u-green-400 {
    color: unquote("rgb(#{$palette-u-green-400})") !important;
  }

  .u-bg-green-400 {
    background-color: unquote("rgb(#{$palette-u-green-400})") !important;
  }
  .u-green-500 {
    color: unquote("rgb(#{$palette-u-green-500})") !important;
  }

  .u-bg-green-500 {
    background-color: unquote("rgb(#{$palette-u-green-500})") !important;
  }
  .u-green-600 {
    color: unquote("rgb(#{$palette-u-green-600})") !important;
  }

  .u-bg-green-600 {
    background-color: unquote("rgb(#{$palette-u-green-600})") !important;
  }
  .u-green-700 {
    color: unquote("rgb(#{$palette-u-green-700})") !important;
  }

  .u-bg-green-700 {
    background-color: unquote("rgb(#{$palette-u-green-700})") !important;
  }
  .u-green-800 {
    color: unquote("rgb(#{$palette-u-green-800})") !important;
  }

  .u-bg-green-800 {
    background-color: unquote("rgb(#{$palette-u-green-800})") !important;
  }
  .u-green-900 {
    color: unquote("rgb(#{$palette-u-green-900})") !important;
  }

  .u-bg-green-900 {
    background-color: unquote("rgb(#{$palette-u-green-900})") !important;
  }


  .u-blue {
    color: unquote("rgb(#{$palette-u-blue-500})") !important;
  }

  .u-bg-blue {
    background-color: unquote("rgb(#{$palette-u-blue-500})") !important;
  }
  .u-blue-100 {
    color: unquote("rgb(#{$palette-u-blue-100})") !important;
  }

  .u-bg-blue-100 {
    background-color: unquote("rgb(#{$palette-u-blue-100})") !important;
  }
  .u-blue-200 {
    color: unquote("rgb(#{$palette-u-blue-200})") !important;
  }

  .u-bg-blue-200 {
    background-color: unquote("rgb(#{$palette-u-blue-200})") !important;
  }
  .u-blue-300 {
    color: unquote("rgb(#{$palette-u-blue-300})") !important;
  }

  .u-bg-blue-300 {
    background-color: unquote("rgb(#{$palette-u-blue-300})") !important;
  }
  .u-blue-400 {
    color: unquote("rgb(#{$palette-u-blue-400})") !important;
  }

  .u-bg-blue-400 {
    background-color: unquote("rgb(#{$palette-u-blue-400})") !important;
  }
  .u-blue-500 {
    color: unquote("rgb(#{$palette-u-blue-500})") !important;
  }

  .u-bg-blue-500 {
    background-color: unquote("rgb(#{$palette-u-blue-500})") !important;
  }
  .u-blue-600 {
    color: unquote("rgb(#{$palette-u-blue-600})") !important;
  }

  .u-bg-blue-600 {
    background-color: unquote("rgb(#{$palette-u-blue-600})") !important;
  }
  .u-blue-700 {
    color: unquote("rgb(#{$palette-u-blue-700})") !important;
  }

  .u-bg-blue-700 {
    background-color: unquote("rgb(#{$palette-u-blue-700})") !important;
  }
  .u-blue-800 {
    color: unquote("rgb(#{$palette-u-blue-800})") !important;
  }

  .u-bg-blue-800 {
    background-color: unquote("rgb(#{$palette-u-blue-800})") !important;
  }
  .u-blue-900 {
    color: unquote("rgb(#{$palette-u-blue-900})") !important;
  }

  .u-bg-blue-900 {
    background-color: unquote("rgb(#{$palette-u-blue-900})") !important;
  }


  .u-cyan {
    color: unquote("rgb(#{$palette-u-cyan-500})") !important;
  }

  .u-bg-cyan {
    background-color: unquote("rgb(#{$palette-u-cyan-500})") !important;
  }
  .u-cyan-100 {
    color: unquote("rgb(#{$palette-u-cyan-100})") !important;
  }

  .u-bg-cyan-100 {
    background-color: unquote("rgb(#{$palette-u-cyan-100})") !important;
  }
  .u-cyan-200 {
    color: unquote("rgb(#{$palette-u-cyan-200})") !important;
  }

  .u-bg-cyan-200 {
    background-color: unquote("rgb(#{$palette-u-cyan-200})") !important;
  }
  .u-cyan-300 {
    color: unquote("rgb(#{$palette-u-cyan-300})") !important;
  }

  .u-bg-cyan-300 {
    background-color: unquote("rgb(#{$palette-u-cyan-300})") !important;
  }
  .u-cyan-400 {
    color: unquote("rgb(#{$palette-u-cyan-400})") !important;
  }

  .u-bg-cyan-400 {
    background-color: unquote("rgb(#{$palette-u-cyan-400})") !important;
  }
  .u-cyan-500 {
    color: unquote("rgb(#{$palette-u-cyan-500})") !important;
  }

  .u-bg-cyan-500 {
    background-color: unquote("rgb(#{$palette-u-cyan-500})") !important;
  }
  .u-cyan-600 {
    color: unquote("rgb(#{$palette-u-cyan-600})") !important;
  }

  .u-bg-cyan-600 {
    background-color: unquote("rgb(#{$palette-u-cyan-600})") !important;
  }
  .u-cyan-700 {
    color: unquote("rgb(#{$palette-u-cyan-700})") !important;
  }

  .u-bg-cyan-700 {
    background-color: unquote("rgb(#{$palette-u-cyan-700})") !important;
  }
  .u-cyan-800 {
    color: unquote("rgb(#{$palette-u-cyan-800})") !important;
  }

  .u-bg-cyan-800 {
    background-color: unquote("rgb(#{$palette-u-cyan-800})") !important;
  }
  .u-cyan-900 {
    color: unquote("rgb(#{$palette-u-cyan-900})") !important;
  }

  .u-bg-cyan-900 {
    background-color: unquote("rgb(#{$palette-u-cyan-900})") !important;
  }


  .u-purple {
    color: unquote("rgb(#{$palette-u-purple-500})") !important;
  }

  .u-bg-purple {
    background-color: unquote("rgb(#{$palette-u-purple-500})") !important;
  }
  .u-purple-100 {
    color: unquote("rgb(#{$palette-u-purple-100})") !important;
  }

  .u-bg-purple-100 {
    background-color: unquote("rgb(#{$palette-u-purple-100})") !important;
  }
  .u-purple-200 {
    color: unquote("rgb(#{$palette-u-purple-200})") !important;
  }

  .u-bg-purple-200 {
    background-color: unquote("rgb(#{$palette-u-purple-200})") !important;
  }
  .u-purple-300 {
    color: unquote("rgb(#{$palette-u-purple-300})") !important;
  }

  .u-bg-purple-300 {
    background-color: unquote("rgb(#{$palette-u-purple-300})") !important;
  }
  .u-purple-400 {
    color: unquote("rgb(#{$palette-u-purple-400})") !important;
  }

  .u-bg-purple-400 {
    background-color: unquote("rgb(#{$palette-u-purple-400})") !important;
  }
  .u-purple-500 {
    color: unquote("rgb(#{$palette-u-purple-500})") !important;
  }

  .u-bg-purple-500 {
    background-color: unquote("rgb(#{$palette-u-purple-500})") !important;
  }
  .u-purple-600 {
    color: unquote("rgb(#{$palette-u-purple-600})") !important;
  }

  .u-bg-purple-600 {
    background-color: unquote("rgb(#{$palette-u-purple-600})") !important;
  }
  .u-purple-700 {
    color: unquote("rgb(#{$palette-u-purple-700})") !important;
  }

  .u-bg-purple-700 {
    background-color: unquote("rgb(#{$palette-u-purple-700})") !important;
  }
  .u-purple-800 {
    color: unquote("rgb(#{$palette-u-purple-800})") !important;
  }

  .u-bg-purple-800 {
    background-color: unquote("rgb(#{$palette-u-purple-800})") !important;
  }
  .u-purple-900 {
    color: unquote("rgb(#{$palette-u-purple-900})") !important;
  }

  .u-bg-purple-900 {
    background-color: unquote("rgb(#{$palette-u-purple-900})") !important;
  }


  .u-gray {
    color: unquote("rgb(#{$palette-u-gray-500})") !important;
  }

  .u-bg-gray {
    background-color: unquote("rgb(#{$palette-u-gray-500})") !important;
  }

  .u-gray-50 {
    color: unquote("rgb(#{$palette-u-gray-50})") !important;
  }

  .u-bg-gray-50 {
    background-color: unquote("rgb(#{$palette-u-gray-50})") !important;
  }
  .u-gray-100 {
    color: unquote("rgb(#{$palette-u-gray-100})") !important;
  }

  .u-bg-gray-100 {
    background-color: unquote("rgb(#{$palette-u-gray-100})") !important;
  }
  .u-gray-200 {
    color: unquote("rgb(#{$palette-u-gray-200})") !important;
  }

  .u-bg-gray-200 {
    background-color: unquote("rgb(#{$palette-u-gray-200})") !important;
  }
  .u-gray-300 {
    color: unquote("rgb(#{$palette-u-gray-300})") !important;
  }

  .u-bg-gray-300 {
    background-color: unquote("rgb(#{$palette-u-gray-300})") !important;
  }
  .u-gray-400 {
    color: unquote("rgb(#{$palette-u-gray-400})") !important;
  }

  .u-bg-gray-400 {
    background-color: unquote("rgb(#{$palette-u-gray-400})") !important;
  }
  .u-gray-500 {
    color: unquote("rgb(#{$palette-u-gray-500})") !important;
  }

  .u-bg-gray-500 {
    background-color: unquote("rgb(#{$palette-u-gray-500})") !important;
  }
  .u-gray-600 {
    color: unquote("rgb(#{$palette-u-gray-600})") !important;
  }

  .u-bg-gray-600 {
    background-color: unquote("rgb(#{$palette-u-gray-600})") !important;
  }
  .u-gray-700 {
    color: unquote("rgb(#{$palette-u-gray-700})") !important;
  }

  .u-bg-gray-700 {
    background-color: unquote("rgb(#{$palette-u-gray-700})") !important;
  }
  .u-gray-800 {
    color: unquote("rgb(#{$palette-u-gray-800})") !important;
  }

  .u-bg-gray-800 {
    background-color: unquote("rgb(#{$palette-u-gray-800})") !important;
  }
  .u-gray-900 {
    color: unquote("rgb(#{$palette-u-gray-900})") !important;
  }
  .u-bg-gray-900 {
    background-color: unquote("rgb(#{$palette-u-gray-900})") !important;
  }
  .u-bg-gray-A100 {
    background-color: unquote("rgb(#{$palette-u-gray-A100})") !important;
  }
  .u-gray-A100 {
    color: unquote("rgb(#{$palette-u-gray-A100})") !important;
  }
  .u-bg-gray-A200 {
    background-color: unquote("rgb(#{$palette-u-gray-A200})") !important;
  }
  .u-gray-A200 {
    color: unquote("rgb(#{$palette-u-gray-A200})") !important;
  }


  // Black

  .bg-black {
    background-color: unquote("rgb(#{$color-black})") !important;
  }

  .black {
    color: unquote("rgb(#{$color-black})") !important;
  }

  // White

  .bg-white {
    background-color: unquote("rgb(#{$color-white})") !important;
  }

  .white {
    color: unquote("rgb(#{$color-white})") !important;
  }
}

// Primary and accent

.bg-primary {
  background-color: unquote("rgb(#{$primary-color})") !important;
}

.bg-primary-contrast {
  background-color: unquote("rgb(#{$color-primary-contrast})") !important;
}

.bg-primary-dark {
  background-color: unquote("rgb(#{$primary-color-dark})") !important;
}

.bg-accent {
  background-color: unquote("rgb(#{$color-accent})") !important;
}

.bg-accent-contrast {
  background-color: unquote("rgb(#{$color-accent-contrast})") !important;
}

.text-primary {
  color: unquote("rgb(#{$primary-color})") !important;
}

.text-primary-contrast {
  color: unquote("rgb(#{$color-primary-contrast})") !important;
}

.text-primary-dark {
  color: unquote("rgb(#{$primary-color-dark})") !important;
}

.text-accent {
  color: unquote("rgb(#{$color-accent})") !important;
}

.text-accent-contrast {
  color: unquote("rgb(#{$color-accent-contrast})") !important;
}
