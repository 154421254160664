@import "../../wui-core/scss/minxin-mixins";

$bar-prefix-cls: $prefix-cls + 'bar';

.#{$bar-prefix-cls} {
    overflow: hidden;
    height: $progress-default-height;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: $border-radius-base;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.#{$bar-prefix-cls}-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: $progress-primary-bg;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    transition: width .6s ease;
}

.#{$bar-prefix-cls}.sm {
    height: $progress-sm-height;
    .#{$bar-prefix-cls}-bar {
        line-height: $progress-sm-lable-line-height;
    }
    .#{$bar-prefix-cls}-label {
        font-size: $progress-sm-lable-font-size;
    }
}

.#{$bar-prefix-cls}.xs {
    height: $progress-xs-height;
    width: $progress-xs-width;
    margin-left: 32px;
    .#{$bar-prefix-cls}-label {
        left: 0;
        color: $progress-xs-lable-color;
        position: absolute;
        font-size: $progress-xs-lable-font-size;
        line-height: $progress-xs-lable-line-height;
    }
}

.#{$bar-prefix-cls}-bar-success {
    @include progress-styles-variant($progress-success-bg);
}

.#{$bar-prefix-cls}-bar-warning {
    @include progress-styles-variant($progress-warning-bg);
}

.#{$bar-prefix-cls}-bar-danger {
    @include progress-styles-variant($progress-danger-bg);
}

.#{$bar-prefix-cls}-bar-info {
    @include progress-styles-variant($progress-info-bg);
}

.#{$bar-prefix-cls}-bar-primary {
    @include progress-styles-variant($progress-primary-bg);
}

.#{$bar-prefix-cls}-bar-dark {
    @include progress-styles-variant($progress-dark-bg);
}

.#{$bar-prefix-cls}-bar-striped,
.#{$bar-prefix-cls}-striped .#{$bar-prefix-cls}-bar {
    background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
    background-size: 40px 40px;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.#{$bar-prefix-cls}-bar.active,
.#{$bar-prefix-cls}.active .#{$bar-prefix-cls}-bar {
    animation: progress-bar-stripes 2s linear infinite;
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}


/* Make clicks pass-through */

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    border-width: 3px 0 0;
    border-top-style: solid;
    border-image: linear-gradient(139deg, #fb8817, #ff4b01, #c12127, #e02aff) 3;
}


/* Fancy blur effect */

#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px);
}


/* Remove these to get rid of the spinner */

#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}