/*
 * 保护有用的浏览器默认样式而不是完全去掉它们
 * 一般化的样式：为大部分HTML元素提供
 * 修复浏览器自身的bug并保证各浏览器的一致性
 * 优化CSS可用性：用一些小技巧
 * 解释代码：用注释和详细的文档来
*/


//使html占用整个屏幕然后设置触摸操作，以避免触摸延迟在移动IE
//2、在IE和iOS中，在方向更改后，防止调整字体大小。

html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -ms-text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
}

/*
* 使body占据整个屏幕删除body边距，因此布局容器不会导致额外的溢出。
* 去掉默认的margin
*/


body {
  margin: 0;
  width: 100%;
  height: 100%;
}


/*
 * 主显示屏复位为IE支持
 * Source: http://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
 */
main {
  display: block;
}

/*
* 对具有hidden属性的元素不应用显示。
* IE 9 and 10 support.
*/
*[hidden] {
  display: none !important;
}

*, :after, :before {
  box-sizing: border-box;
}

// HTML5 display definitions
// ==========================================================================

//
// Add the correct display in IE 9-.
// 1. Add the correct display in Edge, IE, and Firefox.
// 2. Add the correct display in IE.
//

article,
aside,
details, // 1
figcaption,
figure,
footer,
header,
main, // 2
menu,
nav,
section,
summary { // 1
  display: block;
}

//
// Add the correct display in IE 9-.
//

audio,
canvas,
progress,
video {
  display: inline-block;
}

//
// Add the correct display in iOS 4-7.
//

audio:not([controls]) {
  display: none;
  height: 0;
}

//
// Add the correct vertical alignment in Chrome, Firefox, and Opera.
//

progress {
  vertical-align: baseline;
}

//
// Add the correct display in IE 10-.
// 1. Add the correct display in IE.
//

template, // 2
[hidden] {
  display: none;
}

// Links
// ==========================================================================

//
// Remove the gray background on active links in IE 10.
//

a {
  background-color: transparent;
}

//
// Remove the outline on focused links when they are also active or hovered
// in all browsers (opinionated).
//

a:active,
a:hover {
  outline-width: 0;
}

// Text-level semantics
// ==========================================================================

//
// 1. Remove the bottom border in Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
//

abbr[title] {
  border-bottom: none; // 1
  text-decoration: underline; // 2
  text-decoration: underline dotted; // 2
}

//
// Prevent the duplicate application of `bolder` by the next rule in Safari 6.
//

b,
strong {
  font-weight: inherit;
}

//
// Add the correct font weight in Chrome, Edge, and Safari.
//

b,
strong {
  font-weight: bolder;
}

//
// Add the correct font style in Android 4.3-.
//

dfn {
  font-style: italic;
}

//
// Correct the font size and margin on `h1` elements within `section` and
// `article` contexts in Chrome, Firefox, and Safari.
//

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

//
// Add the correct background and color in IE 9-.
//

mark {
  background-color: #ff0;
  color: #000;
}

//
// Add the correct font size in all browsers.
//

small {
  font-size: 80%;
}

//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
//

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

// Embedded content
// ==========================================================================

//
// Remove the border on images inside links in IE 10-.
//

img {
  border-style: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

//
// Hide the overflow in IE.
//

svg:not(:root) {
  overflow: hidden;
}

// Grouping content
// ==========================================================================

//
// 1. Correct the inheritance and scaling of font size in all browsers.
// 2. Correct the odd `em` font sizing in all browsers.
//

code,
kbd,
pre,
samp {
  font-family: var(--ynfw-font-family-tnf, system-ui); // 1
  font-size: 1em; // 2
}

//
// Add the correct margin in IE 8.
//

figure {
  margin: 1em 40px;
}

//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.
//

hr {
  box-sizing: content-box; // 1
  height: 0; // 1
  overflow: visible; // 2
}

// Forms
// ==========================================================================

//
// Change font properties to `inherit` in all browsers (opinionated).
//

button,
input,
select,
textarea {
  font: inherit;
}

//
// Restore the font weight unset by the previous rule.
//

optgroup {
  font-weight: bold;
}

//
// Show the overflow in IE.
// 1. Show the overflow in Edge.
// 2. Show the overflow in Edge, Firefox, and IE.
//

button,
input, // 1
select { // 2
  overflow: visible;
}

//
// Remove the margin in Safari.
// 1. Remove the margin in Firefox and Safari.
//

button,
input,
select,
textarea { // 1
  margin: 0;
}

//
// Remove the inheritence of text transform in Edge, Firefox, and IE.
// 1. Remove the inheritence of text transform in Firefox.
//

button,
select { // 1
  text-transform: none;
}

//
// Change the cursor in all browsers (opinionated).
//

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

//
// Restore the default cursor to disabled elements unset by the previous rule.
//

[disabled] {
  cursor: default;
}

//
// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS.
//

button,
html [type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2
}

//
// Remove the inner border and padding in Firefox.
//

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

//
// Restore the focus styles unset by the previous rule.
//

// button:-moz-focusring,
// input:-moz-focusring {
//   outline: 1px dotted ButtonText;
// }

//
// Change the border, margin, and padding in all browsers (opinionated).
//

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

//
// 1. Correct the text wrapping in Edge and IE.
// 2. Correct the color inheritance from `fieldset` elements in IE.
// 3. Remove the padding so developers are not caught out when they zero out
//    `fieldset` elements in all browsers.
//

legend {
  box-sizing: border-box; // 1
  color: inherit; // 2
  display: table; // 1
  max-width: 100%; // 1
  padding: 0; // 3
  white-space: normal; // 1
}

//
// Remove the default vertical scrollbar in IE.
//

textarea {
  overflow: auto;
}

//
// 1. Add the correct box sizing in IE 10-.
// 2. Remove the padding in IE 10-.
//

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; // 1
  padding: 0; // 2
}

//
// Correct the cursor style of increment and decrement buttons in Chrome.
//

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

//
// Correct the odd appearance of search inputs in Chrome and Safari.
//

[type="search"] {
  -webkit-appearance: textfield;
}

//
// Remove the inner padding and cancel buttons in Chrome on OS X and
// Safari on OS X.
//

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
