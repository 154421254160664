@import "minxin-mixins";

.u-shadow-0dp {
  @include shadow-0dp();
}

.u-shadow-2dp {
  @include shadow-2dp();
}

.u-shadow-3dp {
  @include shadow-3dp();
}

.u-shadow-4dp {
  @include shadow-4dp();
}

.u-shadow-6dp {
  @include shadow-6dp();
}

.u-shadow-8dp {
  @include shadow-8dp();
}

.u-shadow-16dp {
  @include shadow-16dp();
}

.u-shadow-24dp {
  @include shadow-24dp();
}
