/**
 *  tinper-next重写以下新图标字体，注意：
 * 1.iconfont-next.css中字体定义名为 "next-icon"
 * 2.新增图标样式名需在此补充
 * 3.后续所有图标都替换了"uf"字体图标后此文件即可作废了
 */
.uf.uf-danju:before,
.uf.uf-yuandian:before,
.uf.uf-danchuangtuozhuaijiaobiao:before,
.uf.uf-star-3:before,
.uf.uf-star:before,
.uf.uf-star-o:before,
.uf.uf-star-2:before,
.uf.uf-jiantouxia_mianxing:before,
.uf.uf-jiantoushang_mianxing:before,
.uf.uf-yiwancheng:before,
.uf.uf-zushouqi_huise:before,
.uf.uf-zuzhankai_huise:before,
.uf.uf-yindao:before,
.uf.uf-bangzhuzhongxin:before,
.uf.uf-yybs:before,
.uf.uf-xiaoxi:before,
.uf.uf-location:before,
.uf.uf-zhenduan:before,
.uf.uf-shangjia:before,
.uf.uf-tupian:before,
.uf.uf-UImoban:before,
.uf.uf-kuaijiejianbangzhu:before,
.uf.uf-pencil-s:before,
.uf.uf-del:before,
.uf.uf-settings:before,
.uf.uf-anglearrowdown:before,
.uf.uf-xiangshang:before,
.uf.uf-xiangxia1:before,
.uf.uf-arrow-up:before,
.uf.uf-reject-2:before,
.uf.uf-search-light-2:before,
.uf.uf-export:before,
.uf.uf-import:before,
.uf.uf-print:before,
.uf.uf-jinhangzhong:before,
.uf.uf-hebingbumenxinxi:before,
.uf.uf-gengduo:before,
.uf.uf-weikaishi:before,
.uf.uf-hebingrenyuanxinxi:before,
.uf.uf-shujian:before,
.uf.uf-mi:before,
.uf.uf-liebiaoshezhi:before,
.uf.uf-lianwang:before,
.uf.uf-gantanhao:before,
.uf.uf-zhankai:before,
.uf.uf-shouqi:before,
.uf.uf-xiangzuo:before,
.uf.uf-xiangyou:before,
.uf.uf-daoshouye:before,
.uf.uf-a-wenhaomoren:before,
.uf.uf-qingfenxi:before,
.uf.uf-cloud-o-up:before,
.uf.uf-datu:before,
.uf.uf-heart:before,
.uf.uf-heart-o:before,
.uf.uf-shangyitiao-copy:before,
.uf.uf-xiayitiao-copy:before,
.uf.uf-biaotoudingweiyidingweinormal:before,
.uf.uf-dongjie:before,
.uf.uf-weidongjie:before,
.uf.uf-biaodansousuo:before,
.uf.uf-biaotoushaixuanyixuannormal:before,
.uf.uf-shaixuan1-copy:before,
.uf.uf-qiehuanchaxunfangshi:before,
.uf.uf-liulantai-biangeng-bukeyong:before,
.uf.uf-liulantai-ruku:before,
.uf.uf-correct-2:before,
.uf.uf-tongzhi:before,
.uf.uf-hanshu:before,
.uf.uf-jiesuo:before,
.uf.uf-suoding:before,
.uf.uf-appshouqi:before,
.uf.uf-appzhankai:before,
.uf.uf-close:before,
.uf.uf-plus:before,
.uf.uf-hebingzhiweixinxi:before,
.uf.uf-liulantai-tijiao-bukeyong1:before,
.uf.uf-budingzhu_xiao:before,
.uf.uf-dingzhu_xiao:before,
.uf.uf-youjiantou_shuang:before,
.uf.uf-yemianfenlan_zhankai:before,
.uf.uf-yemianfenlan_zhedie:before,
.uf.uf-arrow-up-2:before,
.uf.uf-arrow-down-2:before,
.uf.uf-chaosong:before,
.uf.uf-yunqianming:before,
.uf.uf-qianmingtupian:before,
.uf.uf-bukejian:before,
.uf.uf-changyongyu:before,
.uf.uf-ziliuchengshenpizhong:before,
.uf.uf-quanshan:before,
.uf.uf-zhinengsousuo:before,
.uf.uf-ellipsis:before,
.uf.uf-wentifankui:before,
.uf.uf-qiehuanzhanghao:before,
.uf.uf-shangpinzhongxin:before,
.uf.uf-tuandui:before,
.uf.uf-qiehuanxiaoxie:before,
.uf.uf-qiehuandaxie:before,
.uf.uf-shang:before,
.uf.uf-xia:before,
.uf.uf-chexiao:before,
.uf.uf-daishenhe:before,
.uf.uf-shibai:before,
.uf.uf-top-up:before,
.uf.uf-dingdanguanli:before,
.uf.uf-zuixiaohua:before,
.uf.uf-huichequeren:before,
.uf.uf-tihuan:before,
.uf.uf-zanting:before,
.uf.uf-bofang:before,
.uf.uf-qiye:before,
.uf.uf-dingwei:before,
.uf.uf-chongzhi1:before,
.uf.uf-exc-t:before,
.uf.uf-exc-c-2:before,
.uf.uf-xingzhuangbeifen:before,
.uf.uf-chenggongtishi:before,
.uf.uf-xunizuzhi:before,
.uf.uf-fuzhi2:before,
.uf.uf-ziduanbiangeng:before,
.uf.uf-fujian:before,
.uf.uf-bell-o:before,
.uf.uf-symlist:before,
.uf.uf-calendar:before,
.uf.uf-tuichudenglu:before,
.uf.uf-gerenxinxi:before,
.uf.uf-xiaolian:before,
.uf.uf-shuaxin:before,
.uf.uf-i-c:before,
.uf.uf-rollback:before,
.uf.uf-eye-o:before,
.uf.uf-eye:before,
.uf.uf-erweima:before,
.uf.uf-jiangxu:before,
.uf.uf-shengxu:before,
.uf.uf-tuodong:before,
.uf.uf-zuidahua:before,
.uf.uf-globe:before,
.uf.uf-nodata:before,
.uf.uf-loadingstate:before,
.uf.uf-new:before,
.uf.uf-shoucangliebiao:before,
.uf.uf-zuzhixingbumen:before,
.uf.uf-zuzhi:before,
.uf.uf-bumen:before,
.uf.uf-wenziyanse:before,
.uf.uf-hangneidaima:before,
.uf.uf-wuxuliebiao:before,
.uf.uf-xieti:before,
.uf.uf-suojin:before,
.uf.uf-xiahuaxian:before,
.uf.uf-fengexian:before,
.uf.uf-xiabiao:before,
.uf.uf-yinyong:before,
.uf.uf-geshishua:before,
.uf.uf-zuoduiqi:before,
.uf.uf-shangbiao:before,
.uf.uf-daiban:before,
.uf.uf-chexiao1:before,
.uf.uf-lianjie:before,
.uf.uf-zhongzuo:before,
.uf.uf-quanping:before,
.uf.uf-biaoge:before,
.uf.uf-youduiqi:before,
.uf.uf-zengjiasuojin:before,
.uf.uf-juzhongduiqi:before,
.uf.uf-youxuliebiao:before,
.uf.uf-liangduanduiqi:before,
.uf.uf-jianshaosuojin:before,
.uf.uf-shipin:before,
.uf.uf-biaoqing:before,
.uf.uf-quxiaoquanping:before,
.uf.uf-jiacu:before,
.uf.uf-tupian1:before,
.uf.uf-beijingse1:before,
.uf.uf-shanchuxian1:before,
.uf.uf-touxiang:before,
.uf.uf-fenye:before,
.uf.uf-jianqie:before,
.uf.uf-shijianriqi:before,
.uf.uf-qingchugeshi:before,
.uf.uf-duozhangtupian:before,
.uf.uf-yulan:before,
.uf.uf-maodian:before,
.uf.uf-baiduditu:before,
.uf.uf-load:before,
.uf.uf-zhedie-shouqi:before,
.uf.uf-zhedie-zhankai:before,
.uf.uf-plyr-fast-forward:before,
.uf.uf-xuanzhong:before,
.uf.uf-shoucang:before,
.uf.uf-zhankai1:before,
.uf.uf-shouqi1:before,
.uf.uf-tupianshibai:before,
.uf.uf-mulu:before,
.uf.uf-shanchu:before,
.uf.uf-kapian:before,
.uf.uf-plyr-volume:before,
.uf.uf-qingchushaixuan:before,
.uf.uf-tingyong_line:before,
.uf.uf-hetongxuanqu:before,
.uf.uf-hetongkuangxuan:before,
.uf.uf-zhankai2:before,
.uf.uf-xingzhuangjiehe:before,
.uf.uf-icon_all:before,
.uf.uf-icon-failbeifen:before,
.uf.uf-a-bianzu75beifen5:before,
.uf.uf-icon-jinggaobeifen:before,
.uf.uf-dingzhu:before,
.uf.uf-gaojichaxun:before {
    font-family: "next-icon" !important;
}

