@import "minxin-variables";

html{
  font-size: 10px;
}

body {
  font-family: var(--#{$wui-prefix}-font-family);
  font-size: 1.4rem;
  line-height: 1.4;
}


//图标 左右内间距为5px;
i.uf{
  padding: 0 5px;
}


/*
 * 选择时删除文本阴影，及设置默认选中颜色
 */

::selection {
    background: $global-selected-color;
    text-shadow: none;
}

/*
 * 默认水平规则
 */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

/*
 * 删除音频，画布，iframes，图像，视频和其容器底部之间的差距
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}



/*
 * 仅允许垂直调整textareas的大小
 */

textarea {
    resize: vertical;
}

/* ==========================================================================
   浏览器升级提示
   ========================================================================== */

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}



/*
 * 适用于屏幕阅读器的隐藏
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * 扩展.visuallyhidden类以允许元素在通过键盘导航时是可对焦的：
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}






/* ==========================================================================
   打印样式。内联以避免额外的HTTP请求

   ========================================================================== */

// @media print {
//     *,
//     *:before,
//     *:after,
//     *:first-letter,
//     *:first-line {
//         background: transparent !important;
//         color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
//         box-shadow: none !important;
//         text-shadow: none !important;
//     }

//     a,
//     a:visited {
//         text-decoration: underline;
//     }

//     a[href]:after {
//         content: " (" attr(href) ")";
//     }

//     abbr[title]:after {
//         content: " (" attr(title) ")";
//     }

//     /*
//      * 不显示片段标识符的链接，
//      * 或使用`javascript：`伪协议
//      */

//     a[href^="#"]:after,
//     a[href^="javascript:"]:after {
//         content: "";
//     }

//     pre,
//     blockquote {
//         border: 1px solid #999;
//         page-break-inside: avoid;
//     }

//     /*
//      * 打印表格:
//      * http://css-discuss.incutio.com/wiki/Printing_Tables
//      */

//     thead {
//         display: table-header-group;
//     }

//     tr,
//     img {
//         page-break-inside: avoid;
//     }

//     img {
//         max-width: 100% !important;
//     }

//     p,
//     h2,
//     h3 {
//         orphans: 3;
//         widows: 3;
//     }

//     h2,
//     h3 {
//         page-break-after: avoid;
//     }

// }



/*
*删除FAB按钮周围的不需要的框 ,主要兼容ios的safari和部分android
**/
a, .mdl-accordion, .mdl-button, .mdl-card, .mdl-checkbox, .mdl-dropdown-menu,
.mdl-icon-toggle, .mdl-item, .mdl-radio, .mdl-slider, .mdl-switch, .mdl-tabs__tab {

    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

[dir="rtl"] .filp-img {
    transform: scaleX(-1);
  }
