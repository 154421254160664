@font-face {
    font-family: "next-icon"; /* Project id 2492714 */
    src: url('./iconfont-next.woff?t=1659003247790')format('woff');
}

.uf.uf-kapian:before {
  content: "\e68c";
}

.uf.uf-shanchu:before {
  content: "\e68b";
}

.uf.uf-tupianshibai:before {
  content: "\e68a";
}

.uf.uf-mulu:before {
  content: "\e689";
}

.uf.uf-shoucang:before {
  content: "\e686";
}

.uf.uf-zhankai1:before {
  content: "\e687";
}

.uf.uf-shouqi1:before {
  content: "\e688";
}

.uf.uf-xuanzhong:before {
  content: "\e685";
}

.uf.uf-zhedie-zhankai:before {
  content: "\e684";
}

.uf.uf-zhedie-shouqi:before {
  content: "\e683";
}

.uf.uf-plyr-fast-forward:before {
  content: "\e693";
}
.uf.uf-plyr-volume:before {
  content: "\e697";
}

.uf.uf-load:before {
  content: "\e690";
}

.uf.uf-fenye:before {
    content: "\e678";
}

.uf.uf-jianqie:before {
    content: "\e679";
}

.uf.uf-shijianriqi:before {
    content: "\e67a";
}

.uf.uf-qingchugeshi:before {
    content: "\e67c";
}

.uf.uf-duozhangtupian:before {
    content: "\e683";
}

.uf.uf-yulan:before {
    content: "\e684";
}

.uf.uf-maodian:before {
    content: "\e685";
}

.uf.uf-baiduditu:before {
    content: "\e686";
}

.uf.uf-touxiang:before {
    content: "\e669";
}

.uf.uf-beijingse1:before {
    content: "\e660";
}

.uf.uf-shanchuxian1:before {
    content: "\e677";
}

.uf.uf-wenziyanse:before {
    content: "\e653";
}

.uf.uf-hangneidaima:before {
    content: "\e655";
}

.uf.uf-wuxuliebiao:before {
    content: "\e656";
}

.uf.uf-xieti:before {
    content: "\e657";
}

.uf.uf-suojin:before {
    content: "\e659";
}

.uf.uf-xiahuaxian:before {
    content: "\e65a";
}

.uf.uf-fengexian:before {
    content: "\e65b";
}

.uf.uf-xiabiao:before {
    content: "\e65c";
}

.uf.uf-yinyong:before {
    content: "\e65d";
}

.uf.uf-geshishua:before {
    content: "\e65e";
}

.uf.uf-zuoduiqi:before {
    content: "\e65f";
}

.uf.uf-shangbiao:before {
    content: "\e661";
}

.uf.uf-daiban:before {
    content: "\e662";
}

.uf.uf-chexiao1:before {
    content: "\e663";
}

.uf.uf-lianjie:before {
    content: "\e664";
}

.uf.uf-zhongzuo:before {
    content: "\e665";
}

.uf.uf-quanping:before {
    content: "\e666";
}

.uf.uf-biaoge:before {
    content: "\e66a";
}

.uf.uf-youduiqi:before {
    content: "\e66b";
}

.uf.uf-zengjiasuojin:before {
    content: "\e66c";
}

.uf.uf-juzhongduiqi:before {
    content: "\e66d";
}

.uf.uf-youxuliebiao:before {
    content: "\e66e";
}

.uf.uf-liangduanduiqi:before {
    content: "\e66f";
}

.uf.uf-jianshaosuojin:before {
    content: "\e670";
}

.uf.uf-shipin:before {
    content: "\e671";
}

.uf.uf-biaoqing:before {
    content: "\e672";
}

.uf.uf-quxiaoquanping:before {
    content: "\e673";
}

.uf.uf-jiacu:before {
    content: "\e674";
}

.uf.uf-tupian1:before {
    content: "\e676";
}

.uf.uf-zuzhixingbumen:before {
    content: "\f700";
}

.uf.uf-zuzhi:before {
    content: "\f699";
}

.uf.uf-bumen:before {
    content: "\f694";
}

.uf.uf-shoucangliebiao:before {
    content: "\f691";
}

.uf.uf-new:before {
    content: "\f690";
}

.uf.uf-loadingstate:before {
    content: "\e600";
}

.uf.uf-danju:before {
    content: "\f6a6";
}

.uf.uf-yuandian:before {
    content: "\f684";
}

.uf.uf-danchuangtuozhuaijiaobiao:before {
    content: "\f67a";
}

.uf.uf-star-3:before {
    content: "\f679";
}

.uf.uf-star:before {
    content: "\e728";
}

.uf.uf-star-o:before {
    content: "\e726";
}

.uf.uf-star-2:before {
    content: "\e727";
}

.uf.uf-jiantouxia_mianxing:before {
    content: "\f676";
}

.uf.uf-jiantoushang_mianxing:before {
    content: "\f674";
}

.uf.uf-yiwancheng:before {
    content: "\e680";
}

.uf.uf-zushouqi_huise:before {
    content: "\f6a5";
}

.uf.uf-zuzhankai_huise:before {
    content: "\f6a7";
}

.uf.uf-yindao:before {
    content: "\ea50";
}

.uf.uf-bangzhuzhongxin:before {
    content: "\ea51";
}

.uf.uf-yybs:before {
    content: "\e816";
}

.uf.uf-xiaoxi:before {
    content: "\e9b5";
}

.uf.uf-location:before {
    content: "\e796";
}

.uf.uf-zhenduan:before {
    content: "\ea13";
}

.uf.uf-shangjia:before {
    content: "\e99a";
}

.uf.uf-tupian:before {
    content: "\f818";
}

.uf.uf-UImoban:before {
    content: "\f85a";
}

.uf.uf-kuaijiejianbangzhu:before {
    content: "\f680";
}

.uf.uf-pencil-s:before {
    content: "\e77d";
}

.uf.uf-del:before {
    content: "\e654";
}

.uf.uf-settings:before {
    content: "\e70c";
}

.uf.uf-anglearrowdown:before {
    content: "\e9cf";
}

.uf.uf-xiangshang:before {
    content: "\e814";
}

.uf.uf-xiangxia1:before {
    content: "\e815";
}

.uf.uf-arrow-up:before {
    content: "\e8d2";
}

.uf.uf-reject-2:before {
    content: "\e6ad";
}

.uf.uf-search-light-2:before {
    content: "\e627";
}



.uf.uf-export:before {
    content: "\e9d4";
}

.uf.uf-import:before {
    content: "\e9d5";
}

.uf.uf-print:before {
    content: "\e60e";
}

.uf.uf-jinhangzhong:before {
    content: "\e67b";
}

.uf.uf-hebingbumenxinxi:before {
    content: "\e681";
}

.uf.uf-gengduo:before {
    content: "\e67f";
}

.uf.uf-weikaishi:before {
    content: "\e67e";
}

.uf.uf-hebingrenyuanxinxi:before {
    content: "\e67d";
}

.uf.uf-shujian:before {
    content: "\e773";
}

.uf.uf-mi:before {
    content: "\e993";
}

.uf.uf-liebiaoshezhi:before {
    content: "\f641";
}

.uf.uf-lianwang:before {
    content: "\f642";
}

.uf.uf-gantanhao:before {
    content: "\f643";
}

.uf.uf-zhankai:before {
    content: "\f645";
}

.uf.uf-shouqi:before {
    content: "\f646";
}

.uf.uf-xiangzuo:before {
    content: "\f649";
}

.uf.uf-xiangyou:before {
    content: "\f64a";
}

.uf.uf-daoshouye:before {
    content: "\f64c";
}

.uf.uf-a-wenhaomoren:before {
    content: "\f64d";
}

.uf.uf-qingfenxi:before {
    content: "\f64e";
}

.uf.uf-cloud-o-up:before {
    content: "\e6ac";
}

.uf.uf-datu:before {
    content: "\f650";
}

.uf.uf-heart:before {
    content: "\e6a4";
}

.uf.uf-heart-o:before {
    content: "\e6a3";
}

.uf.uf-shangyitiao-copy:before {
    content: "\e80a";
}

.uf.uf-xiayitiao-copy:before {
    content: "\e809";
}

.uf.uf-biaotoudingweiyidingweinormal:before {
    content: "\f656";
}

.uf.uf-dongjie:before {
    content: "\e788";
}

.uf.uf-weidongjie:before {
    content: "\e789";
}

.uf.uf-biaodansousuo:before {
    content: "\e82a";
}

.uf.uf-biaotoushaixuanyixuannormal:before {
    content: "\f659";
}

.uf.uf-shaixuan1-copy:before {
    content: "\e80b";
}

.uf.uf-qiehuanchaxunfangshi:before {
    content: "\f65b";
}

.uf.uf-liulantai-biangeng-bukeyong:before {
    content: "\f65c";
}

.uf.uf-liulantai-ruku:before {
    content: "\f65e";
}

.uf.uf-correct-2:before {
    content: "\e658";
}

.uf.uf-tongzhi:before {
    content: "\f660";
}

.uf.uf-hanshu:before {
    content: "\f662";
}

.uf.uf-jiesuo:before {
    content: "\f663";
}

.uf.uf-suoding:before {
    content: "\f664";
}

.uf.uf-appshouqi:before {
    content: "\f666";
}

.uf.uf-appzhankai:before {
    content: "\f667";
}

.uf.uf-close:before {
    content: "\e76b";
}

.uf.uf-plus:before {
    content: "\e763";
}

.uf.uf-hebingzhiweixinxi:before {
    content: "\e682";
}

.uf.uf-liulantai-tijiao-bukeyong1:before {
    content: "\f66b";
}

.uf.uf-budingzhu_xiao:before {
    content: "\f6b9";
}

.uf.uf-dingzhu_xiao:before {
    content: "\f6ba";
}

.uf.uf-youjiantou_shuang:before {
    content: "\f66c";
}

.uf.uf-yemianfenlan_zhankai:before {
    content: "\f66d";
}

.uf.uf-yemianfenlan_zhedie:before {
    content: "\f66f";
}

.uf.uf-arrow-up-2:before {
    content: "\e6c1";
}

.uf.uf-arrow-down-2:before {
    content: "\e667";
}

.uf.uf-chaosong:before {
    content: "\f689";
}

.uf.uf-yunqianming:before {
    content: "\f692";
}

.uf.uf-qianmingtupian:before {
    content: "\f693";
}

.uf.uf-bukejian:before {
    content: "\f695";
}

.uf.uf-changyongyu:before {
    content: "\f697";
}

.uf.uf-ziliuchengshenpizhong:before {
    content: "\f698";
}

.uf.uf-quanshan:before {
    content: "\f6a1";
}

.uf.uf-zhinengsousuo:before {
    content: "\e5b9";
}

.uf.uf-ellipsis:before {
    content: "\f60a";
}

.uf.uf-wentifankui:before {
    content: "\f713";
}

.uf.uf-qiehuanzhanghao:before {
    content: "\f71c";
}

.uf.uf-shangpinzhongxin:before {
    content: "\f635";
}

.uf.uf-tuandui:before {
    content: "\e9bb";
}

.uf.uf-qiehuanxiaoxie:before {
    content: "\f731";
}

.uf.uf-qiehuandaxie:before {
    content: "\f732";
}

.uf.uf-shang:before {
    content: "\f78d";
}

.uf.uf-xia:before {
    content: "\f78e";
}

.uf.uf-chexiao:before {
    content: "\f7c1";
}

.uf.uf-daishenhe:before {
    content: "\f7d2";
}

.uf.uf-shibai:before {
    content: "\f787";
}

.uf.uf-top-up:before {
    content: "\e668";
}

.uf.uf-dingdanguanli:before {
    content: "\f801";
}

.uf.uf-zuixiaohua:before {
    content: "\e811";
}

.uf.uf-huichequeren:before {
    content: "\f813";
}

.uf.uf-tihuan:before {
    content: "\f84a";
}

.uf.uf-zanting:before {
    content: "\f850";
}

.uf.uf-bofang:before {
    content: "\f851";
}

.uf.uf-qiye:before {
    content: "\e999";
}

.uf.uf-dingwei:before {
    content: "\f858";
}

.uf.uf-chongzhi1:before {
    content: "\f998";
}

.uf.uf-exc-t:before {
    content: "\e60a";
}

.uf.uf-exc-c-2:before {
    content: "\e675";
}

.uf.uf-xingzhuangbeifen:before {
    content: "\f99c";
}

.uf.uf-chenggongtishi:before {
    content: "\f677";
}

.uf.uf-xunizuzhi:before {
    content: "\f9a1";
}

.uf.uf-fuzhi2:before {
    content: "\f9bc";
}

.uf.uf-ziduanbiangeng:before {
    content: "\f614";
}

.uf.uf-fujian:before {
    content: "\f615";
}

.uf.uf-bell-o:before {
    content: "\e6d4";
}

.uf.uf-symlist:before {
    content: "\e6be";
}

.uf.uf-calendar:before {
    content: "\e646";
}

.uf.uf-tuichudenglu:before {
    content: "\f607";
}

.uf.uf-gerenxinxi:before {
    content: "\f608";
}

.uf.uf-xiaolian:before {
    content: "\f683";
}

.uf.uf-shuaxin:before {
    content: "\f688";
}

.uf.uf-i-c:before {
    content: "\e6cd";
}

.uf.uf-rollback:before {
    content: "\f696";
}

.uf.uf-eye-o:before {
    content: "\e69c";
}

.uf.uf-eye:before {
    content: "\e692";
}

.uf.uf-erweima:before {
    content: "\f6be";
}

.uf.uf-jiangxu:before {
    content: "\f855";
}

.uf.uf-shengxu:before {
    content: "\f856";
}

.uf.uf-tuodong:before {
    content: "\e805";
}

.uf.uf-zuidahua:before {
    content: "\e7cc";
}

.uf.uf-globe:before {
    content: "\e9db";
}

.uf.uf-nodata:before {
    content: "\f604";
}

.uf.uf-qingchushaixuan:before {
    content: "\e68d";
}

.uf.uf-tingyong_line:before {
    content: "\e694";
}

.uf.uf-hetongxuanqu:before {
    content: "\e710";
}

.uf.uf-hetongkuangxuan:before {
    content: "\e70f";
}

.uf.uf-zhankai2:before {
    content: "\e716";
}

.uf.uf-xingzhuangjiehe:before {
    content: "\e713";
}

.uf.uf-icon_all:before {
    content: "\e714";
}

.uf.uf-icon-failbeifen:before {
    content: "\e715";
}

.uf.uf-a-bianzu75beifen5:before {
    content: "\e711";
}

.uf.uf-icon-jinggaobeifen:before {
    content: "\e712";
}

.uf.uf-dingzhu:before {
    content: "\e68e";
}
.uf.uf-gaojichaxun:before {
    content: "\e68f";
}

