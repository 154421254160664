@import "../../wui-core/scss/minxin-variables";

$collapse-prefix-cls: $prefix-cls + 'collapse';

.#{$collapse-prefix-cls}-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.#{$collapse-prefix-cls}-content {
  display: none;
}

.#{$collapse-prefix-cls}-content.in {
  display: block;
}
